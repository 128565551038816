import confetti from 'canvas-confetti';

// Variante 1: Größerer und längerer Konfetti-Effekt
const triggerLargerConfetti = () => {
  confetti({
    particleCount: 150,
    spread: 100,
    origin: { y: 0.6 },
    colors: ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff'],
    ticks: 300,
    gravity: 0.8,
    scalar: 1.2,
    shapes: ['circle', 'square']
  });
};

// Variante 2: Konfetti-Regen von oben
const triggerConfettiRain = () => {
  confetti({
    particleCount: 200,
    startVelocity: 30,
    spread: 360,
    origin: { x: Math.random(), y: -0.1 },
    ticks: 400,
    gravity: 0.5,
    scalar: 1.5
  });
};

// Variante 3: Langanhaltender Konfetti-Effekt
const triggerLongLastingConfetti = () => {
  const end = Date.now() + (5 * 1000); // Läuft für 5 Sekunden

  const frame = () => {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: ['#ff0000', '#00ff00', '#0000ff']
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: ['#ff0000', '#00ff00', '#0000ff']
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  };

  frame();
};

// Variante 4: Konfetti-Explosion
const triggerConfettiExplosion = () => {
  const count = 200;
  const defaults = {
    origin: { y: 0.7 }
  };

  function fire(particleRatio, opts) {
    confetti(Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio)
    }));
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

export { triggerLargerConfetti, triggerConfettiRain, triggerLongLastingConfetti, triggerConfettiExplosion };