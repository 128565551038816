import { useState, useCallback, useEffect } from 'react';
import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import useOpenAIChat from './useOpenAI';

const debug = false;

const errorCodeToDescription = {
  'PGK': 'Phonem-Graphem-Korrespondenz',
  'PGK-V': 'Verwechslung von Vokalen',
  'PGK-K': 'Verwechslung von Konsonanten',
  'MP': 'Morphologische Prinzipien',
  'MP-WB': 'Fehler bei der Wortbildung',
  'MP-FL': 'Falsche Flexion',
  'OR': 'Orthographische Regeln',
  'OR-RS': 'Rechtschreibregeln',
  'OR-DP': 'Doppelkonsonanten',
  'OR-UE': 'Umlaute und \'ß\'',
  'SS': 'Silbenstruktur',
  'SS-SB': 'Silbentrennung bei Worttrennung',
  'AS': 'Akzente und Sonderzeichen',
  'AS-AK': 'Akzentsetzung',
  'AS-SZ': 'Sonderzeichen',
  'FS': 'Fremdwortschreibung',
  'FS-E': 'Eingedeutschte Fremdwörter',
  'FS-U': 'Unveränderte Fremdwörter',
  'ZG': 'Zusammen- und Getrenntschreibung',
  'ZG-ZS': 'Zusammenschreibung',
  'ZG-GS': 'Getrenntschreibung',
  'IP': 'Interpunktion',
  'IP-K': 'Kommasetzung',
  'IP-P': 'Punktsetzung',
  'HV': 'Homophone und verwechselte Wörter',
  'HV-H': 'Homophone',
  'HV-VW': 'Verwechselte Wörter',
  'GF': 'Grammatikalische Fehler',
  'GF-AT': 'Artikel',
  'GF-PR': 'Präpositionen',
  'SF': 'Semantische Fehler',
  'SF-SB': 'Bedeutungsverwechslungen',
  'TF': 'Typographische Fehler',
  'TF-T': 'Tippfehler'
};

export function useVocabErrorTip() {
  const [tip, setTip] = useState('');
  const [streamingTip, setStreamingTip] = useState('');
  const { getResponse, streamResponse } = useOpenAIChat();

  const getErrorTip = useCallback(async (vocabId, foreignWord, germanWord, language, isReverseOrder) => {
    if (debug) console.log("getErrorTip called with:", { vocabId, foreignWord, germanWord, language });
    try {
      // Fetch the vocab document
      const vocabRef = doc(db, 'vocabList', vocabId);
      const vocabSnap = await getDoc(vocabRef);

      if (!vocabSnap.exists()) {
        if (debug) console.log("Vocab document does not exist");
        return "Kein Tipp verfügbar";
      }

      const vocabData = vocabSnap.data();
      const direction = isReverseOrder ? 'foreign_to_de' : 'de_to_foreign';
      const errors = (vocabData.errors && vocabData.errors[direction]) || {};
      const promtForOrder = isReverseOrder ? `Es wurde das Fremdwort "${foreignWord}" in der Fremdsprache "${language}" abgefragt und der User musste das deutsche Wort "${germanWord}" theoretisch Wissen. ` : `Es wurde das deutsche Wort "${germanWord}" abgefragt und der User musste das Fremdwort "${foreignWord}" in der Fremdsprache "${language}" theoretisch Wissen. `;


      if (debug) console.log("Vocab data:", vocabData);
      if (debug) console.log("Errors:", errors);

      if (Object.keys(errors).length === 0) {
        if (debug) console.log("No errors found for this vocab");
        return "Keine Fehler gefunden. Gut gemacht!";
      }

      // Process error data
      const errorSummary = Object.entries(errors).map(([code, errorData]) => {
        const frequency = errorData.length;
        const lastOccurrence = new Date(Math.max(...errorData.map(e => new Date(e.date))));
        const incorrectAnswers = errorData.map(e => e.incorrectAnswer).join(', ');
        const description = errorCodeToDescription[code] || code;
        return `Fehlerart: ${description}, Häufigkeit ${frequency}, letztes Auftreten ${lastOccurrence.toISOString().split('T')[0]}, falsche Antworten: ${incorrectAnswers}`;
      }).join('; ');

      // Prepare the prompt for OpenAI
      const prompt = `
        Als Sprachlehrer für ${language} hilfst du einem Schüler, der Schwierigkeiten mit dem Wort "${isReverseOrder ? germanWord : foreignWord}" hat. ${promtForOrder}
        Hier ist eine Zusammenfassung der Fehlerhistorie des Schülers für dieses Wort:
        Heutiges Datum: ${new Date().toISOString().split('T')[0]}

        ${errorSummary}

        Basierend auf dieser Information, gib bitte einen spezifischen Tipp, der dem Schüler hilft, sich dieses Wort besser zu merken und die gemachten Fehler zu vermeiden. 
        Konzentriere dich dabei auf die spezifischen Schwierigkeiten, die der Schüler mit diesem Wort hat, und vermeide allgemeine Ratschläge wie "Wende das Wort häufig an". Dein Tipp muss auf jeden Fall stimmen. Aussagen wie Schule auf Englisch wird nicht mit "sch" geschrieben, sondern mit einem anderen Laut, sind falsch und dürfen auf keinen Fall vorkommen. Benenne auch alle Stellen im Wort, bei dem die Fehlerart vorkommen kann. Zum Beispiel bei school kann das Problem beim "sch" Laut sein, der anders ausgesprochen wird als geschrieben, aber zusätzlich auch bei dem "oo", was theoretisch als "u" oder auch als "o" geschrieben werden könnte.

        Wichtig:
        - Gib den Tipp auf Deutsch.
        - Der Tipp sollte kurz und prägnant sein (2-3 Sätze).
        - Erwähne das zu lernende Wort "${isReverseOrder ? germanWord : foreignWord}" nicht direkt in deinem Tipp. Auch sollten Teile deines Tipps nicht auf Teile des gesuchten Worts hinweisen. Suche dir ein anderes Wort, bei dem man das gleiche Prinzip des Fehlers demonstrieren kann.
        - Wenn du ein Beispiel geben möchtest, verwende ein anderes Wort, das das gleiche Prinzip demonstriert.
        - Wenn Du mit Beispielen, Bildern, und Vergleichen arbeitest, stelle sicher, dass sie auf Deutsch Sinn machen und das Verständnis des Schülers erhöhen. Aussagen wie diese hier machen nämlich keinen Sinn auf Deutsch und verwirren nur: "Eine Pflegeeinrichtung für ältere Menschen ist nicht dasselbe wie etwas Glänzendes oder Professoren, sondern ähnlich wie eine Haustür, an der du die genaue Bedeutung erkennst."

        Formuliere deinen Tipp jetzt, halte dann inne und überprüfe nocheinmal, dass deine Aussagen stimmen, deine Beispiele in Deutsch sowie in der Fremdsprache stimmen und kein Flüchtigkeitsfehler passiert ist:
      `;

      if (debug) console.log("Sending prompt to OpenAI:", prompt);

      // Get the response from OpenAI
      const fullResponse = await getResponse(prompt);
      if (debug) console.log("Generated tip:", fullResponse);

      // Trim and limit the response to 2-3 sentences
      const trimmedResponse = fullResponse.trim();
      const sentences = trimmedResponse.split(/[.!?]+/).filter(sentence => sentence.trim() !== '');
      const limitedResponse = sentences.slice(0, 3).join('. ') + '.';

      setTip(limitedResponse);
      return limitedResponse;

    } catch (error) {
      if (debug) console.error("Error in getErrorTip:", error);
      setTip('');
      setStreamingTip('');
      return "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
    }
  }, [getResponse]);

  // Effect to update streamingTip
  useEffect(() => {
    setStreamingTip(streamResponse);
  }, [streamResponse]);

  return { tip, streamingTip, getErrorTip, setTip };
}
