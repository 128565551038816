import { auth } from '../firebase/config';
import { db } from '../firebase/config';
import { doc, updateDoc } from 'firebase/firestore';

export function useChangeVocab() {
  const changeVocab = async (docId, foreign, german, knownCounter, languageId, nextTraining, additionalInfo) => {
    const userId = auth.currentUser?.uid; // Get the current user ID

    const vocabRef = doc(db, 'vocabList', docId);

    await updateDoc(vocabRef, { 
      foreign: foreign,
      german: german,
      additionalInfo,
      knownCounter: Number(knownCounter), // Ensure knownCounter is a number
      languageId: languageId,
      nextTraining: nextTraining,
      userId: userId // Store the user ID
    });
  };

  return {
    changeVocab
  };
}