import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config';

const errorMessages = {
  'auth/invalid-credential': 'Ups, da stimmt was nicht. Überprüf mal deine E-Mail und dein Passwort!',
  'auth/user-not-found': 'Hm, wir kennen diese E-Mail-Adresse nicht. Schon registriert?',
  'auth/wrong-password': 'Oh je, das Passwort passt nicht. Probiers nochmal!',
  'auth/email-already-in-use': 'Diese E-Mail ist schon vergeben. Vielleicht willst du dich einloggen?',
  'auth/weak-password': 'Dein Passwort könnte stärker sein. Wie wärs mit was Kreativerem?',
  'auth/user-disabled': 'Dein Konto macht gerade Pause. Sprich mal mit unserem Support-Team!',
  'auth/too-many-requests': 'Wow, nicht so schnell! Mach mal ne kurze Pause und versuchs dann nochmal.',
  'auth/email-not-verified': 'Fast geschafft! Schau mal in deine Mails und bestätige deine E-Mail-Adresse.',
};

export function getErrorMessage(errorCode) {
  return errorMessages[errorCode] || 'Hoppla! Da ist was schiefgelaufen. Versuchs später nochmal, okay?';
}

export function useLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const login = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(getErrorMessage(error.code));
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    login,
    error
  };
}