import React, { useState, useEffect, useCallback } from 'react';
import { auth, db } from '../firebase/config';
import { updateProfile, updateEmail, updatePassword, sendEmailVerification } from 'firebase/auth';
import { collection, query, getDocs } from 'firebase/firestore';
import { 
  Container, Typography, TextField, Button, Grid, Paper, IconButton,
  Snackbar, Alert
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [usageStats, setUsageStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [debugInfo, setDebugInfo] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchUsageStats = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    let debugText = `Fetching stats for user: ${userId}\n`;
    try {
      const statsRef = collection(db, 'userStats', userId, 'dailyStats');
      const q = query(statsRef);

      debugText += `Query: ${JSON.stringify(q)}\n`;

      const querySnapshot = await getDocs(q);
      
      debugText += `Query snapshot size: ${querySnapshot.size}\n`;

      let allStats = querySnapshot.docs.map(doc => {
        debugText += `Document ID: ${doc.id}, Data: ${JSON.stringify(doc.data())}\n`;
        return {
          ...doc.data(),
          date: doc.id,
        };
      });

      debugText += `All stats: ${JSON.stringify(allStats)}\n`;

      setUsageStats(allStats);
    } catch (err) {
      debugText += `Error: ${err.message}\n`;
      setError('Fehler beim Laden der Nutzungsstatistiken: ' + err.message);
    } finally {
      setLoading(false);
      setDebugInfo(debugText);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setDisplayName(user.displayName || '');
        setEmail(user.email || '');
        fetchUsageStats(user.uid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [fetchUsageStats]);

  const getMonthData = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const monthData = Array.from({ length: daysInMonth }, (_, i) => ({
      date: `${year}-${String(month + 1).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`,
      activeMinutes: 0
    }));

    usageStats.forEach(stat => {
      const statDate = new Date(stat.date);
      if (statDate.getFullYear() === year && statDate.getMonth() === month) {
        const dayIndex = statDate.getDate() - 1;
        monthData[dayIndex] = { ...monthData[dayIndex], ...stat };
      }
    });

    return monthData;
  };

  const currentMonthData = getMonthData(currentMonth.getFullYear(), currentMonth.getMonth());

  const handleUpdateProfile = async () => {
    try {
      await updateProfile(auth.currentUser, { displayName });
      setSnackbar({
        open: true,
        message: 'Profil erfolgreich aktualisiert',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Fehler beim Aktualisieren des Profils: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const handleUpdateEmail = async () => {
    try {
      await updateEmail(auth.currentUser, email);
      await sendEmailVerification(auth.currentUser);
      setSnackbar({
        open: true,
        message: 'E-Mail-Adresse aktualisiert. Bitte überprüfen Sie Ihr Postfach für die Verifizierungs-E-Mail.',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Fehler beim Aktualisieren der E-Mail: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const handleUpdatePassword = async () => {
    try {
      await updatePassword(auth.currentUser, newPassword);
      setSnackbar({
        open: true,
        message: 'Passwort erfolgreich aktualisiert',
        severity: 'success'
      });
      setNewPassword('');
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Fehler beim Aktualisieren des Passworts: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`Datum: ${label}`}</p>
          <p>{`Aktive Minuten: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  if (!user) return <Typography>Laden...</Typography>;

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>Profil</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleUpdateProfile}>Namen aktualisieren</Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleUpdateEmail}>E-Mail aktualisieren</Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Neues Passwort"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleUpdatePassword}>Passwort ändern</Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>Nutzungsstatistiken</Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <IconButton onClick={handlePreviousMonth}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <Typography variant="h6">
                {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
              </Typography>
              <IconButton onClick={handleNextMonth}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Grid>
            {loading ? (
              <Typography>Lade Statistiken...</Typography>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={currentMonthData}>
                  <XAxis 
                    dataKey="date" 
                    tickFormatter={(tick) => new Date(tick).getDate()}
                    interval={0}
                  />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="activeMinutes" fill="#2196F3" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Profile;