import { auth } from '../../firebase/config';
import { db } from '../../firebase/config';
import { doc, updateDoc } from 'firebase/firestore';

export function useChangeVocab() {
  const changeVocab = async (docId, changes) => {
    const userId = auth.currentUser?.uid;
    const vocabRef = doc(db, 'vocabList', docId);

    // Füge userId zum changes-Objekt hinzu
    const updatedChanges = { ...changes, userId };

    // Konvertiere alle Felder mit dem Namen 'knownCounter' zu Zahlen
    if ('knownCounter' in updatedChanges) {
      updatedChanges.knownCounter = Number(updatedChanges.knownCounter);
    }

    await updateDoc(vocabRef, updatedChanges);
  };

  return {
    changeVocab
  };
}