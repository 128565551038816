import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Button, Container, IconButton, Menu, MenuItem, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLogout } from '../hooks/useLogout';
import ScoreBox from '../components/ScoreBox';
import { useCheckScoreDate } from '../hooks/useCheckScoreDate';
import { setCurrentUser } from '../redux/userSlice';

export default function Navbar({ navigateTo }) {
  const { logout } = useLogout();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.currentUser);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useCheckScoreDate(currentUser?.uid);

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(setCurrentUser(null));
      navigateTo('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    if (currentUser && currentUser.emailVerified) {
      navigateTo('/profile');
    } else {
      navigateTo('/login');
    }
    handleClose();
  };

  const handleHomeClick = () => {
    if (currentUser && !currentUser.emailVerified) {
      navigateTo('/login');
    } else {
      navigateTo('/');
    }
  };

  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="lg">
        <Toolbar sx={{ padding: '0.5rem 0' }}>
          <Box 
            sx={{ 
              flexGrow: 1, 
              display: 'flex', 
              alignItems: 'center', 
              height: '64px',
              cursor: 'pointer'
            }}
            onClick={handleHomeClick}
          >
            <img
              src="/BrainWizzr Logo.svg"
              alt="BrainWizzr Logo"
              style={{ height: '100%', width: 'auto', marginRight: '10px' }}
            />
            {!isMobile && (
              <Typography 
                variant="h6" 
                component="div" 
                sx={{ 
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 600,
                  fontSize: '1.5rem',
                  color: 'inherit'
                }}
              >
                BrainWizzr
              </Typography>
            )}
          </Box>
          {location.pathname !== '/' && (
            <Button color="inherit" onClick={handleHomeClick}>
              Start
            </Button>
          )}
          {currentUser && currentUser.emailVerified && (
            <ScoreBox userId={currentUser.uid} />
          )}
          {!currentUser && (
            <>
              <Button color="inherit" onClick={() => navigateTo('/login')}>
                Anmelden
              </Button>
              <Button color="inherit" onClick={() => navigateTo('/signup')}>
                Registrieren
              </Button>
            </>
          )}
          {currentUser && (
            <>
              <IconButton
                color="inherit"
                onClick={handleMenu}
                aria-label="Dein Konto"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {currentUser.emailVerified ? (
                  <MenuItem onClick={handleProfileClick}>Dein Profil</MenuItem>
                ) : (
                  <MenuItem onClick={() => navigateTo('/login')}>E-Mail bestätigen</MenuItem>
                )}
                <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}