import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { useVocabList } from '../hooks/useVocabList';
import { useSelector } from 'react-redux';
import { useDeleteVocab } from '../hooks/useDeleteVocab';
import { useChangeVocab } from '../hooks/useChangeVocab';
import {
    Card,
    CardContent,
    Typography,
    LinearProgress,
    IconButton,
    TextField,
    Box,
    Grid,
    Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

export default function VocabList() {
    const [user] = useAuthState(auth);
    const userId = user ? user.uid : null;
    const language = useSelector(state => state.language);
    const { vocabPairs, error } = useVocabList(userId, language);
    const { deleteVocab } = useDeleteVocab();
    const { changeVocab } = useChangeVocab();

    const [editingId, setEditingId] = useState(null);
    const [german, setGerman] = useState('');
    const [foreign, setForeign] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString('de-DE', { day: 'numeric', month: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit' });
    };

    if (!language) {
        return <LinearProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">Error loading vocab list: {error.message}</Typography>;
    }

    if (!vocabPairs) {
        return <LinearProgress />;
    }

    const handleEdit = (vocabPair) => {
        setEditingId(vocabPair.id);
        setGerman(vocabPair.german);
        setForeign(vocabPair.foreign);
        setAdditionalInfo(vocabPair.additionalInfo || '');
    };

    const handleSave = async (vocabPair) => {
        await changeVocab(
            vocabPair.id,
            foreign,
            german,
            vocabPair.knownCounter,
            vocabPair.languageId,
            vocabPair.nextTraining,
            additionalInfo
        );
        setEditingId(null);
    };

    const handleCancel = () => {
        setEditingId(null);
        setGerman('');
        setForeign('');
        setAdditionalInfo('');
    };

    const filteredVocabPairs = vocabPairs.filter(vocabPair =>
        vocabPair.german.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vocabPair.foreign.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (vocabPair.additionalInfo && vocabPair.additionalInfo.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <div>
            <TextField
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                label="Suche"
                variant="outlined"
                sx={{ marginBottom: 2 }}
            />
            {filteredVocabPairs.slice().sort((a, b) => a.german.localeCompare(b.german)).map((vocabPair) => (
                <Card key={vocabPair.id} elevation={2} sx={{ marginBottom: 2 }}>
                    <CardContent>
                        {editingId === vocabPair.id ? (
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        value={german}
                                        onChange={(e) => setGerman(e.target.value)}
                                        label="Deutsch"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        value={foreign}
                                        onChange={(e) => setForeign(e.target.value)}
                                        label={language.language}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        value={additionalInfo}
                                        onChange={(e) => setAdditionalInfo(e.target.value)}
                                        label="Zusatzinfo"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={() => handleSave(vocabPair)}>
                                            <SaveIcon />
                                        </IconButton>
                                        <IconButton onClick={handleCancel}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        ) : (
                            <Box>
                                <Typography variant="h5" component="div" sx={{ fontWeight: 'medium', mb: 1 }}>
                                    {vocabPair.german} - {vocabPair.foreign}
                                </Typography>
                                {vocabPair.additionalInfo && (
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        {vocabPair.additionalInfo}
                                    </Typography>
                                )}
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <Chip
                                        label={`${vocabPair.knownCounter} mal gewusst`}
                                        size="small"
                                        sx={{ mr: 1 }}
                                    />
                                    <Chip
                                        label={`Nächstes Training: ${formatDate(vocabPair.nextTraining)}`}
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton onClick={() => handleEdit(vocabPair)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => deleteVocab(vocabPair.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            ))}
        </div>
    );
}