import { collection, doc, deleteDoc, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase/config'; // Import the db object from your Firebase config
import { useAuthState } from 'react-firebase-hooks/auth'; // Import the useAuthState hook

const useDeleteLanguage = () => {
  // Get the current user
  const auth = getAuth();
  const [user] = useAuthState(auth);

  const deleteLanguage = async (languageId) => {
    try {
      // Delete the language from the user's language list in the database
      await deleteDoc(doc(db, 'languageList', languageId));
  
      // Delete all vocabularies of this language for this user
      const vocabQuery = query(collection(db, 'vocabList'), where('userId', '==', user.uid), where('languageId', '==', languageId));
      const vocabQuerySnapshot = await getDocs(vocabQuery);
      for (let vocabDoc of vocabQuerySnapshot.docs) {
        await deleteDoc(doc(db, 'vocabList', vocabDoc.id));
      }
  
      // Return a success message
      return { message: 'Sprache und zugehörige Vokabeln erfolgreich gelöscht.' };
    } catch (error) {
      console.error('Error deleting language: ', error);
      throw error;
    }
  };

  return deleteLanguage;
};

export default useDeleteLanguage;