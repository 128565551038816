import OpenAI from 'openai';
import { useCallback, useState, useRef } from 'react';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});

function useOpenAiTextToSpeech() {
  const [isStreaming, setIsStreaming] = useState(false);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const audioElementRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const pendingBuffersRef = useRef([]);

  const createAudioContext = useCallback(() => {
    if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    return audioContextRef.current;
  }, []);

  const stopCurrentAudio = useCallback(() => {
    if (sourceNodeRef.current) {
      sourceNodeRef.current.stop();
      sourceNodeRef.current.disconnect();
      sourceNodeRef.current = null;
    }
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      audioElementRef.current.src = '';
    }
    if (mediaSourceRef.current && mediaSourceRef.current.readyState === 'open') {
      try {
        endOfStreamWhenReady(mediaSourceRef.current);
      } catch (error) {
        console.error('Error ending media stream:', error);
      }
    }
    setIsStreaming(false);
    pendingBuffersRef.current = [];
  }, []);

  const ensureAudioContextReady = useCallback(async (audioContext) => {
    if (audioContext.state === 'suspended') {
      await audioContext.resume();
    }
    return new Promise((resolve) => {
      if (audioContext.state === 'running') {
        resolve();
      } else {
        audioContext.addEventListener('statechange', function handler() {
          if (audioContext.state === 'running') {
            audioContext.removeEventListener('statechange', handler);
            resolve();
          }
        });
      }
    });
  }, []);

  const appendNextBuffer = useCallback(() => {
    if (pendingBuffersRef.current.length && sourceBufferRef.current && !sourceBufferRef.current.updating) {
      const nextBuffer = pendingBuffersRef.current.shift();
      try {
        sourceBufferRef.current.appendBuffer(nextBuffer);
      } catch (error) {
        console.error('Error appending buffer:', error);
      }
    }
  }, []);

  const endOfStreamWhenReady = useCallback((mediaSource) => {
    if (mediaSource.readyState === 'open') {
      if (!mediaSource.sourceBuffers.length || !mediaSource.sourceBuffers[0].updating) {
        mediaSource.endOfStream();
      } else {
        setTimeout(() => endOfStreamWhenReady(mediaSource), 100);
      }
    }
  }, []);

  const clearMediaSource = useCallback(() => {
    if (mediaSourceRef.current) {
      if (mediaSourceRef.current.readyState === 'open') {
        while (mediaSourceRef.current.sourceBuffers.length > 0) {
          mediaSourceRef.current.removeSourceBuffer(mediaSourceRef.current.sourceBuffers[0]);
        }
        mediaSourceRef.current.endOfStream();
      }
      mediaSourceRef.current = null;
    }
    sourceBufferRef.current = null;
  }, []);

  const playText = useCallback(async (text) => {
    stopCurrentAudio();
    clearMediaSource();
    setIsStreaming(true);

    try {
      const audioContext = createAudioContext();
      await ensureAudioContextReady(audioContext);

      const response = await openai.audio.speech.create({
        model: "tts-1-hd",
        voice: "fable",
        response_format: "mp3",
        input: text,
      });

      const reader = response.body.getReader();

      mediaSourceRef.current = new MediaSource();
      audioElementRef.current = new Audio();
      audioElementRef.current.src = URL.createObjectURL(mediaSourceRef.current);

      let isSourceOpen = false;
      mediaSourceRef.current.addEventListener('sourceopen', async () => {
        isSourceOpen = true;
        if (!mediaSourceRef.current.sourceBuffers.length) {
          sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg');
          sourceBufferRef.current.addEventListener('updateend', appendNextBuffer);

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            pendingBuffersRef.current.push(value);
            if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
              appendNextBuffer();
            }
          }

          endOfStreamWhenReady(mediaSourceRef.current);
        }
      });

      audioElementRef.current.oncanplay = () => {
        audioElementRef.current.play();
      };

      audioElementRef.current.onended = () => {
        setIsStreaming(false);
        clearMediaSource();
      };

      // Ensure MediaSource is properly closed if it wasn't opened
      setTimeout(() => {
        if (!isSourceOpen && mediaSourceRef.current) {
          endOfStreamWhenReady(mediaSourceRef.current);
        }
      }, 1000);

    } catch (err) {
      console.error('Error in playText:', err);
      setIsStreaming(false);
      stopCurrentAudio();
      clearMediaSource();
      throw err;
    }
  }, [stopCurrentAudio, createAudioContext, ensureAudioContextReady, appendNextBuffer, endOfStreamWhenReady, clearMediaSource]);

  return { playText, isStreaming };
}

export default useOpenAiTextToSpeech;
