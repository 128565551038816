import { db } from '../firebase/config';
import { doc, setDoc, updateDoc, getDoc, increment } from 'firebase/firestore';
import store from '../redux/store';

class UsageTrackingService {
  constructor() {
    this.lastInteractionTime = Date.now();
    this.activeMinutes = 0;
    this.interactionsThisInterval = 0;
    this.consecutiveActiveIntervals = 0;
    this.interval = null;
    this.recordInteraction = this.recordInteraction.bind(this);
    this.unsubscribe = null;
  }

  init() {
    // Subscribe to the Redux store
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const user = state.user.currentUser;
      const isFullyAuthenticated = user !== null && user.emailVerified;

      if (isFullyAuthenticated && !this.interval) {
        this.startTracking();
      } else if (!isFullyAuthenticated && this.interval) {
        this.stopTracking();
      }
    });

    // Initial check
    const state = store.getState();
    const user = state.user.currentUser;
    if (user && user.emailVerified) {
      this.startTracking();
    }
  }

  startTracking() {
    this.interval = setInterval(() => this.checkAndUpdateActiveTime(), 30000); // Check every 30 seconds
    
    // Desktop event listeners
    window.addEventListener('mousemove', this.recordInteraction);
    window.addEventListener('keydown', this.recordInteraction);
    
    // Mobile event listeners
    window.addEventListener('touchstart', this.recordInteraction);
    window.addEventListener('touchmove', this.recordInteraction);
    window.addEventListener('touchend', this.recordInteraction);
  }

  stopTracking() {
    clearInterval(this.interval);
    this.interval = null;
    
    // Remove all event listeners
    window.removeEventListener('mousemove', this.recordInteraction);
    window.removeEventListener('keydown', this.recordInteraction);
    window.removeEventListener('touchstart', this.recordInteraction);
    window.removeEventListener('touchmove', this.recordInteraction);
    window.removeEventListener('touchend', this.recordInteraction);
  }

  recordInteraction() {
    this.interactionsThisInterval++;
  }

  checkAndUpdateActiveTime() {
    if (this.interactionsThisInterval > 0) {
      this.consecutiveActiveIntervals++;
      if (this.consecutiveActiveIntervals === 2) {
        this.activeMinutes++;
        this.updateFirestore();
        this.consecutiveActiveIntervals = 0;
      }
    } else {
      this.consecutiveActiveIntervals = 0;
    }
    
    this.interactionsThisInterval = 0;
  }

  async updateFirestore() {
    const state = store.getState();
    const user = state.user.currentUser;
    if (user && user.emailVerified) {
      const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
      const userStatsRef = doc(db, 'userStats', user.uid);
      const dailyStatsRef = doc(userStatsRef, 'dailyStats', date);
 
      try {
        // Prüfen, ob das übergeordnete userStats-Dokument existiert
        const userStatsSnap = await getDoc(userStatsRef);
 
        if (!userStatsSnap.exists()) {
          // Erstellen des übergeordneten userStats-Dokuments, falls es nicht existiert
          await setDoc(userStatsRef, { createdAt: new Date() });
        }
 
        // Prüfen, ob das tägliche Statistik-Dokument existiert
        const dailyStatsSnap = await getDoc(dailyStatsRef);
 
        if (dailyStatsSnap.exists()) {
          // Dokument existiert, also aktualisieren wir es
          await updateDoc(dailyStatsRef, {
            activeMinutes: increment(1)
          });
        } else {
          // Dokument existiert nicht, also erstellen wir es
          await setDoc(dailyStatsRef, {
            activeMinutes: 1
          });
        }
        console.log('Usage tracking data updated successfully');
      } catch (error) {
        console.error('Error updating usage tracking data:', error);
      }
    }
  }

  cleanup() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.stopTracking();
  }
}

export const usageTrackingService = new UsageTrackingService();