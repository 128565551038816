import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config'; // Import the db object from your Firebase config

const useChangeLanguage = () => {
  const changeLanguage = async (languageId, newLanguage) => {
    try {
      // Get a reference to the language document
      const languageRef = doc(db, 'languageList', languageId);

      // Update the language in the user's language list in the database
      await updateDoc(languageRef, { language: newLanguage });

      // Return a success message
      return { message: 'Sprache erfolgreich geändert.' };
    } catch (error) {
      console.error('Error changing language: ', error);
      throw error;
    }
  };

  return changeLanguage;
};

export default useChangeLanguage;