import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Divider,
  Link
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ProtectedContact = ({ type, value }) => {
    const [deobfuscated, setDeobfuscated] = useState('');
  
    useEffect(() => {
      // Einfache Obfuskation: Umkehrung des Strings
      setDeobfuscated(value.split('').reverse().join(''));
    }, [value]);
  
    const handleClick = (e) => {
      e.preventDefault();
      if (type === 'email') {
        window.location.href = `mailto:${deobfuscated.split('').reverse().join('')}`;
      } else if (type === 'tel') {
        window.location.href = `tel:${deobfuscated.split('').reverse().join('')}`;
      }
    };
  
    return (
      <Link 
        href="#" 
        onClick={handleClick} 
        sx={{ 
          direction: 'rtl', 
          unicodeBidi: 'bidi-override',
          textDecoration: 'none',
          color: 'inherit',
          '&:hover': {
            textDecoration: 'underline',
          }
        }}
      >
        {deobfuscated}
      </Link>
    );
  };

  const Section = ({ title, content }) => (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${title}-content`}
        id={`panel-${title}-header`}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {typeof content === 'string' ? (
          <Typography component="div">
            {content.split('\n').map((paragraph, index) => (
              <Typography key={index} paragraph>
                {paragraph.trim()}
              </Typography>
            ))}
          </Typography>
        ) : (
          content
        )}
      </AccordionDetails>
    </Accordion>
  );
  
const LegalPage = ({ title, sections }) => (
  <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
    <Paper elevation={3} sx={{ p: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {title}
      </Typography>
      <Divider sx={{ mb: 3 }} />
      {sections.map((section, index) => (
        <Section key={index} title={section.title} content={section.content} />
      ))}
      <Typography variant="body2" sx={{ mt: 3, textAlign: 'right' }}>
        Stand: {new Date().toLocaleDateString()}
      </Typography>
    </Paper>
  </Container>
);

const renderContent = (content) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return content.split('\n').map((line, lineIndex) => {
    const parts = line.split(urlRegex);
    
    return (
      <Typography key={lineIndex} paragraph>
        {parts.map((part, partIndex) => {
          if (urlRegex.test(part)) {
            return (
              <Link key={partIndex} href={part} target="_blank" rel="noopener noreferrer">
                {part}
              </Link>
            );
          } else if (part.includes('{PROTECTED_PHONE}')) {
            return <ProtectedContact key={partIndex} type="tel" value="+49 2223 796 00 44" />;
          } else if (part.includes('{PROTECTED_EMAIL}')) {
            return <ProtectedContact key={partIndex} type="email" value="info@halo-handel.de" />;
          }
          return <React.Fragment key={partIndex}>{part}</React.Fragment>;
        })}
      </Typography>
    );
  });
};

export const AGBPage = () => {
    const agbSections = [
      {
        title: "1. Geltungsbereich",
        content: `1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") gelten für die Nutzung der BrainWizzr App (nachfolgend "App"), die von der Halo Handelsgesellschaft mbH, Flurgasse 76, 53639 Königswinter (nachfolgend "Anbieter") betrieben wird.
  
  1.2 Für die Vertragsbeziehung zwischen dem Anbieter und dem Benutzer gelten ausschließlich diese AGB in ihrer zum Zeitpunkt des Vertragsschlusses gültigen Fassung. Abweichende Bedingungen des Benutzers werden nicht anerkannt, es sei denn, der Anbieter stimmt ihrer Geltung ausdrücklich schriftlich zu.
  
  1.3 Die AGB gelten sowohl für Verbraucher als auch für Unternehmer, soweit nicht ausdrücklich anders vermerkt. Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.`
      },
      {
        title: "2. Leistungsbeschreibung",
        content: `2.1 BrainWizzr ist eine Lern-App, die primär dem Sprachenlernen dient, aber auch andere Sachthemen wie die Vorbereitung auf Führerscheinprüfungen umfasst. Die App ist derzeit ausschließlich als Web-App über den Browser verfügbar.
  
  2.2 Die App bietet kostenlose Grundfunktionen sowie einen kostenpflichtigen Premium-Zugang mit erweiterten Funktionen.
  
  2.3 Benutzer haben die Möglichkeit, eigene Lerninhaltssammlungen zu erstellen und diese über die App an andere Benutzer zu verkaufen. Der Anbieter behält 30% des Verkaufspreises als Gebühr ein. Käufer können die erworbenen Lerninhaltssammlungen bewerten, und diese Bewertungen sind für andere Benutzer sichtbar.`
      },
      {
        title: "3. Registrierung und Benutzerkonto",
        content: `3.1 Zur Nutzung der App ist eine Registrierung erforderlich. Die Registrierung ist kostenfrei.
  
  3.2 Bei der Registrierung sind vollständige und wahrheitsgemäße Angaben zu machen. Änderungen der Daten sind dem Anbieter unverzüglich mitzuteilen.
  
  3.3 Das Benutzerkonto ist nicht übertragbar. Der Benutzer ist verpflichtet, seine Zugangsdaten geheim zu halten und vor dem Zugriff Dritter zu schützen.
  
  3.4 Der Anbieter behält sich das Recht vor, Registrierungen ohne Angabe von Gründen abzulehnen.`
      },
      {
        title: "4. Vertragsschluss und Laufzeit",
        content: `4.1 Die Präsentation der App und ihrer Funktionen stellt kein bindendes Angebot dar. Der Benutzer gibt durch seine Bestellung ein verbindliches Angebot ab.
  
  4.2 Bei kostenpflichtigen Produkten kommt der Vertrag mit Zusendung der Auftragsbestätigung per E-Mail zustande.
  
  4.3 Für den Premium-Zugang werden folgende Abonnement-Modelle angeboten:
     a) Monatliches Abonnement, monatlich kündbar
     b) Jährliches Abonnement, jährlich kündbar
  
  4.4 Die Laufzeit des jeweiligen Abonnements verlängert sich automatisch um die gewählte Laufzeit, wenn nicht fristgerecht gekündigt wird.`
      },
      {
        title: "5. Preise und Zahlungsbedingungen",
        content: `5.1 Die angegebenen Preise sind in Euro und verstehen sich einschließlich der gesetzlichen Mehrwertsteuer.
  
  5.2 Die Zahlung erfolgt über den Zahlungsdienstleister Stripe. Es stehen die von Stripe angebotenen Zahlungsmethoden zur Verfügung, insbesondere Kreditkartenzahlung und Bankeinzug.
  
  5.3 Bei Verkauf von Lerninhaltssammlungen durch Benutzer wird die Gebühr von 30% automatisch vom Verkaufspreis abgezogen.
  
  5.4 Die Vergütung ist mit Vertragsschluss fällig. Bei wiederkehrenden Zahlungen ist die Vergütung zu Beginn jeder Abrechnungsperiode fällig.`
      },
      {
        title: "6. Widerrufsrecht",
        content: `6.1 Verbrauchern steht ein gesetzliches Widerrufsrecht zu. Die Widerrufsbelehrung finden Sie am Ende dieser AGB.
  
  6.2 Das Widerrufsrecht erlischt bei digitalen Inhalten, wenn der Anbieter mit der Ausführung des Vertrags begonnen hat, nachdem der Verbraucher ausdrücklich zugestimmt hat, dass der Anbieter mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt, und seine Kenntnis davon bestätigt hat, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.`
      },
      {
        title: "7. Nutzungsrechte und -beschränkungen",
        content: `7.1 Der Anbieter räumt dem Benutzer für die Dauer des Vertrags ein einfaches, nicht übertragbares Recht zur Nutzung der App und ihrer Inhalte ein.
  
  7.2 Der Benutzer ist nicht berechtigt, die App oder Teile davon zu vervielfältigen, zu bearbeiten, zu verbreiten oder öffentlich zugänglich zu machen, es sei denn, dies ist ausdrücklich erlaubt.
  
  7.3 Bei der Erstellung und dem Verkauf von Lerninhaltssammlungen ist der Benutzer verpflichtet, keine Rechte Dritter zu verletzen und keine rechtswidrigen oder anstößigen Inhalte zu verwenden.
  
  7.4 Der Anbieter behält sich das Recht vor, Inhalte zu prüfen und gegebenenfalls zu entfernen, wenn sie gegen diese AGB oder geltendes Recht verstoßen.`
      },
      {
        title: "8. Altersbeschränkung",
        content: `8.1 Die Nutzung der kostenpflichtigen Inhalte der App ist Personen unter 18 Jahren nur mit Zustimmung der Erziehungsberechtigten gestattet.
  
  8.2 Der Anbieter behält sich vor, einen Altersnachweis zu verlangen.`
      },
      {
        title: "9. Datenschutz",
        content: `9.1 Der Anbieter erhebt und verwendet personenbezogene Daten des Benutzers nur im Rahmen der geltenden Datenschutzbestimmungen. Nähere Informationen sind in der Datenschutzerklärung zu finden.
  
  9.2 Der Benutzer wird darauf hingewiesen, dass zur Durchführung der Leistungen auch Dienste von Drittanbietern genutzt werden, einschließlich Google-Dienste (Firebase Hosting, Firebase Firestore, Firebase Auth), OpenAI KI-Modelle und Stripe für die Zahlungsabwicklung. Dabei können Daten auch in den USA verarbeitet und gespeichert werden.
  
  9.3 Bei Löschung des Benutzerkontos werden die Daten aus den Datenbanken des Anbieters entfernt. Ob die jeweiligen Partner Backups speichern, die der Anbieter nicht löschen kann, ist nicht bekannt.`
      },
      {
        title: "10. Haftungsbeschränkung",
        content: `10.1 Der Anbieter haftet unbeschränkt für Vorsatz und grobe Fahrlässigkeit sowie nach Maßgabe des Produkthaftungsgesetzes.
  
  10.2 Für leichte Fahrlässigkeit haftet der Anbieter nur bei Verletzung einer wesentlichen Vertragspflicht und nur in Höhe des vorhersehbaren, vertragstypischen Schadens.
  
  10.3 Der Anbieter übernimmt keine Haftung für die Richtigkeit und Vollständigkeit der von Benutzern erstellten Lerninhaltssammlungen.`
      },
      {
        title: "11. Änderungen der AGB",
        content: `11.1 Der Anbieter behält sich vor, diese AGB jederzeit zu ändern. Änderungen werden dem Benutzer spätestens sechs Wochen vor ihrem Inkrafttreten per E-Mail mitgeteilt.
  
  11.2 Widerspricht der Benutzer der Geltung der neuen AGB nicht innerhalb von sechs Wochen nach Empfang der Mitteilung, gelten die geänderten AGB als angenommen. Der Anbieter wird den Benutzer in der Änderungsmitteilung auf die Bedeutung dieser Frist hinweisen.`
      },
      {
        title: "12. Schlussbestimmungen",
        content: `12.1 Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.
  
  12.2 Ist der Benutzer Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist Gerichtsstand für alle Streitigkeiten aus diesem Vertragsverhältnis der Sitz des Anbieters.
  
  12.3 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.`
      },
      {
        title: "13. Widerrufsbelehrung",
        content: `Widerrufsrecht
  
  Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
  
  Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
  
  Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Halo Handelsgesellschaft mbH, Flurgasse 76, 53639 Königswinter) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
  
  Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
  
  Folgen des Widerrufs
  
  Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
  
  Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
  
  Besondere Hinweise
  
  Ihr Widerrufsrecht erlischt vorzeitig, wenn wir die Dienstleistung vollständig erbracht haben und mit der Ausführung der Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre ausdrückliche Zustimmung gegeben haben und gleichzeitig Ihre Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht bei vollständiger Vertragserfüllung durch uns verlieren.
  
  Ende der Widerrufsbelehrung`
      },
      {
        title: "Muster-Widerrufsformular",
        content: `(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
  
  An
  Halo Handelsgesellschaft mbH
  Flurgasse 76
  53639 Königswinter
  
  Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*)
  
  Bestellt am (*)/erhalten am (*)
  
  Name des/der Verbraucher(s)
  
  Anschrift des/der Verbraucher(s)
  
  Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
  
  Datum
  
  (*) Unzutreffendes streichen.`
      } 
    ];
  
    return <LegalPage title="Allgemeine Geschäftsbedingungen der BrainWizzr App" sections={agbSections} />;
  };
  
export const DatenschutzPage = () => {
  const datenschutzSections = [
    {
      title: "1. Einleitung und Verantwortlicher",
      content: `Wir, die Halo Handelsgesellschaft mbH, Flurgasse 76, 53639 Königswinter (nachfolgend "wir" oder "uns"), nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst. In dieser Datenschutzerklärung informieren wir Sie darüber, wie wir Ihre personenbezogenen Daten im Rahmen der Nutzung unserer BrainWizzr App (nachfolgend "App") verarbeiten.

Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:

Halo Handelsgesellschaft mbH
Flurgasse 76
53639 Königswinter
E-Mail: {PROTECTED_EMAIL}`
    },
    {
        title: "2. Erhobene Daten und Zwecke der Verarbeitung",
        content: `2.1 Bei der Nutzung der App
  
  - Registrierungsdaten: Bei der Registrierung erheben wir Ihren Benutzernamen, E-Mail-Adresse und Passwort. Diese Daten sind erforderlich, um Ihnen ein Benutzerkonto zur Verfügung zu stellen.
  - Nutzungsdaten: Wir erfassen Daten über Ihre Lernaktivitäten, erstellte Inhalte und Interaktionen innerhalb der App, um Ihnen personalisierte Lernerfahrungen zu bieten und die App zu verbessern.
  - Zahlungsdaten: Bei kostenpflichtigen Funktionen werden Zahlungsinformationen über unseren Zahlungsdienstleister Stripe verarbeitet.
  
  2.2 Bei der Erstellung und dem Verkauf von Lerninhalten
  
  Wenn Sie Lerninhalte erstellen und verkaufen, verarbeiten wir zusätzlich:
  - Informationen zu Ihren erstellten Inhalten
  - Verkaufs- und Umsatzdaten
  - Bewertungen und Feedback zu Ihren Inhalten`
      },  
    {
      title: "3. Rechtsgrundlagen der Verarbeitung",
      content: `- Die Verarbeitung zur Bereitstellung der App-Funktionen erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Vertragserfüllung).
- Die Verarbeitung zu Analysezwecken erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse an der Verbesserung unserer App).
- Sofern Sie eingewilligt haben, erfolgt die Verarbeitung auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO.`
    },
    {
      title: "4. Empfänger der Daten",
      content: `Wir setzen folgende Dienstleister ein, die Zugriff auf Ihre Daten haben können:

- Google Firebase für Hosting, Datenbank (Firestore) und Authentifizierung
- OpenAI für KI-Modelle zur Verbesserung der Lernerfahrung
- Stripe für die Zahlungsabwicklung

Diese Dienstleister sind sorgfältig ausgewählt und vertraglich verpflichtet, Ihre Daten nur in unserem Auftrag und nach unseren Weisungen zu verarbeiten.`
    },
    {
      title: "5. Datenübermittlung in Drittländer",
      content: `Einige unserer Dienstleister (Google, OpenAI, Stripe) haben ihren Sitz in den USA. Die Übermittlung erfolgt auf Grundlage von Standardvertragsklauseln der EU-Kommission und zusätzlichen Garantien zum Schutz Ihrer Daten.`
    },
    {
      title: "6. Speicherdauer",
      content: `Wir speichern Ihre Daten, solange Ihr Benutzerkonto besteht. Nach Löschung Ihres Kontos werden Ihre personenbezogenen Daten gelöscht, es sei denn, gesetzliche Aufbewahrungspflichten erfordern eine längere Speicherung.`
    },
    {
      title: "7. Ihre Rechte",
      content: `Sie haben folgende Rechte bezüglich Ihrer personenbezogenen Daten:

- Recht auf Auskunft (Art. 15 DSGVO)
- Recht auf Berichtigung (Art. 16 DSGVO)
- Recht auf Löschung (Art. 17 DSGVO)
- Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)
- Recht auf Datenübertragbarkeit (Art. 20 DSGVO)
- Widerspruchsrecht (Art. 21 DSGVO)
- Recht auf Widerruf einer Einwilligung (Art. 7 Abs. 3 DSGVO)

Zur Ausübung Ihrer Rechte kontaktieren Sie uns bitte unter den oben angegebenen Kontaktdaten.

Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten zu beschweren.`
    },
    {
      title: "8. Datensicherheit",
      content: `Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder den Zugriff unberechtigter Personen zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.`
    },
    {
      title: "9. Änderungen der Datenschutzerklärung",
      content: `Wir behalten uns vor, diese Datenschutzerklärung anzupassen, um sie stets den aktuellen rechtlichen Anforderungen anzupassen oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.`
    }
  ];

  const renderedSections = datenschutzSections.map(section => ({
    ...section,
    content: renderContent(section.content)
  }));

  return <LegalPage title="Datenschutzerklärung für BrainWizzr" sections={renderedSections} />;
};

export const ImpressumPage = () => {
  const impressumSections = [
    {
      title: "Angaben gemäß § 5 TMG",
      content: `Halo Handelsgesellschaft mbH
Flurgasse 76
53639 Königswinter`
    },
    {
      title: "Vertreten durch",
      content: `Daniel Eich`
    },
    {
      title: "Kontakt",
      content: `Telefon: {PROTECTED_PHONE}
E-Mail: {PROTECTED_EMAIL}`
    },
    {
      title: "Registereintrag",
      content: `Eingetragen im Handelsregister.
Registergericht: Amtsgericht Siegburg
Registernummer: 17473`
    },
    {
      title: "Umsatzsteuer-ID",
      content: `Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
DE360437442`
    },
    {
      title: "Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV",
      content: `Daniel Eich
Halo Handelsgesellschaft mbH
Flurgasse 76
53639 Königswinter`
    },
    {
      title: "Streitschlichtung",
      content: `Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:

https://ec.europa.eu/consumers/odr/

Unsere E-Mail-Adresse finden Sie oben im Impressum.

Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.`
    }
  ];

  const renderedSections = impressumSections.map(section => ({
    ...section,
    content: renderContent(section.content)
  }));

  return <LegalPage title="Impressum" sections={renderedSections} />;
};

const LegalPages = {
    AGBPage,
    DatenschutzPage,
    ImpressumPage
  };

export default LegalPages;