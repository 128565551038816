import { useState, useEffect } from "react";
import { db } from "../firebase/config";
import { collection, query, where, onSnapshot } from "firebase/firestore";   

export const useCollection = (c, userId, languageId) => {  
    const [documents, setDocuments] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        setError(null);

        let unsub = () => {};

        if (userId && languageId) {
            let ref = collection(db, c);
            let q = query(ref, where("userId", "==", userId), where("languageId", "==", languageId));

            unsub = onSnapshot(q, (snapshot) => {
                try {
                    let results = snapshot.docs.map(doc => ({...doc.data(), id: doc.id }));
                    setDocuments(results);
                    setError(null);
                } catch (err) {
                    console.error('Error in onSnapshot:', err);
                    setError('Failed to fetch documents');
                    setDocuments(null);
                } finally {
                    setIsLoading(false);
                }
            }, (err) => {
                console.error('Error setting up snapshot listener:', err);
                setError('Failed to set up listener');
                setDocuments(null);
                setIsLoading(false);
            });
        } else {
            setDocuments(null);
            setError(null);
            setIsLoading(false);
        }

        return () => unsub();
    }, [c, userId, languageId])

    return { documents, error, isLoading }
}