import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { useSelector } from 'react-redux';
import useOpenAI from '../hooks/useOpenAI';
import useAiVoiceInput from '../hooks/useAiVoiceInput';
import useOpenAiTextToSpeech from '../hooks/useOpenAiTextToSpeech';
import { useVocabList } from '../hooks/useVocabList';
import { getVoiceToTextVocabularyPrompt } from '../utils/aiPrompt';
import {
    Button,
    Container,
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    LinearProgress,
    Chip,
    CircularProgress,
    Snackbar,
    Switch,
    FormControlLabel
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ReactMarkdown from 'react-markdown';

export default function ConversationTraining({ userName }) {
    const [user] = useAuthState(auth);
    const userId = user ? user.uid : null;
    const language = useSelector(state => state.language);
    const { vocabPairs, error } = useVocabList(userId, language);
    const { response, getResponse, streamResponse } = useOpenAI();
    const { voiceResponse, getVoiceResponse } = useAiVoiceInput();
    const { playText, isStreaming } = useOpenAiTextToSpeech();

    const [vocabIndex, setVocabIndex] = useState(0);
    const [filteredVocabPairs, setFilteredVocabPairs] = useState([]);
    const [audioFile, setAudioFile] = useState(null);
    const [isAudioRecording, setIsAudioRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [showAiResponse, setShowAiResponse] = useState(false);
    const [knownFilter, setKnownFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedWord, setSelectedWord] = useState('');
    const [isBlurred, setIsBlurred] = useState(true);
    const [isAdditionalInfoBlurred, setIsAdditionalInfoBlurred] = useState(true);
    const [currentStreamResponse, setCurrentStreamResponse] = useState('');
    const [isProcessingResponse, setIsProcessingResponse] = useState(false);
    const [isResponseReady, setIsResponseReady] = useState(false);
    const [isPlayTextEnabled, setIsPlayTextEnabled] = useState(true);
    const [responseSnackbarMessage, setResponseSnackbarMessage] = useState('');
    const [openResponseSnackbar, setOpenResponseSnackbar] = useState(false);
    const [lastProcessedResponse, setLastProcessedResponse] = useState('');
    const [isVoiceResponseProcessed, setIsVoiceResponseProcessed] = useState(false);
    const [isNewRecording, setIsNewRecording] = useState(false);
    const processingTimeoutRef = useRef(null);

    useEffect(() => {
        if (vocabPairs) {
            let filtered = [...vocabPairs];
            
            if (knownFilter !== 'all') {
                filtered = filtered.filter(pair => pair.knownCounter === parseInt(knownFilter));
            }
            
            if (searchTerm) {
                filtered = filtered.filter(pair => 
                    pair.german.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    pair.foreign.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (pair.additionalInfo && pair.additionalInfo.toLowerCase().includes(searchTerm.toLowerCase()))
                );
            }
            
            setFilteredVocabPairs(filtered);
        }
    }, [vocabPairs, knownFilter, searchTerm]);

    useEffect(() => {
        if (streamResponse) {
          setCurrentStreamResponse(streamResponse);
          console.log("Received stream response:", streamResponse);
        }
    }, [streamResponse]);
    
    useEffect(() => {
        if (response) {
          setCurrentStreamResponse('');
          setIsResponseReady(true);
          console.log("Received final response:", response);
          if (isPlayTextEnabled) {
            console.log("Playing text response");
            playText(response);
          }
        }
    }, [response, playText, isPlayTextEnabled]);

    const startRecording = useCallback(() => {
        if (recorder && recorder.state === "recording") {
            console.log("Recording already in progress");
            return;
        }
    
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const newRecorder = new MediaRecorder(stream);
                newRecorder.start();
                newRecorder.ondataavailable = e => {
                    const file = new File([e.data], "audio.webm", { type: "audio/webm" });
                    setAudioFile(file);
                    console.log("Audio file created:", file);
                };
                setRecorder(newRecorder);
                console.log("Recording started");
            })
            .catch(error => {
                console.error("Error starting recording:", error);
            });
    }, [recorder]);

    const resetState = useCallback(() => {
        setShowAiResponse(false);
        setIsBlurred(true);
        setIsAdditionalInfoBlurred(true);
        setCurrentStreamResponse('');
        setIsResponseReady(false);
        setLastProcessedResponse('');
        setAudioFile(null);
        setIsProcessingResponse(false);
        setIsVoiceResponseProcessed(false);
        setIsNewRecording(false);
        if (processingTimeoutRef.current) {
            clearTimeout(processingTimeoutRef.current);
        }
    }, []);

    const handleNextVocab = useCallback(() => {
        setVocabIndex((prevIndex) => (prevIndex + 1) % filteredVocabPairs.length);
        resetState();
        console.log("Moving to next vocabulary");
    }, [filteredVocabPairs.length, resetState]);

    useEffect(() => {
        if (audioFile) {
            console.log("Processing audio file");
            getVoiceResponse(audioFile).then(response => {
                console.log("Voice response received:", response);
                setLastProcessedResponse(response);
            }).catch(error => {
                console.error("Error in voice response:", error);
            });
        }
    }, [audioFile, getVoiceResponse]);

    const getSentenceFeedback = useCallback(async () => {
        if (!voiceResponse || filteredVocabPairs.length === 0 || isProcessingResponse || isVoiceResponseProcessed || !isNewRecording) {
            console.log("Skipping sentence feedback due to conditions not met");
            return;
        }
        
        setIsProcessingResponse(true);
        setIsVoiceResponseProcessed(true);
        setIsNewRecording(false);
        setResponseSnackbarMessage(`KI-Trainer analysiert die Antwort.`);
        setOpenResponseSnackbar(true);
        const germanWord = filteredVocabPairs[vocabIndex].german;
        const expectedForeignWord = filteredVocabPairs[vocabIndex].foreign;
        const selectedLanguage = language.language;
        
        try {
            console.log("Requesting AI response for:", voiceResponse);
            await getResponse(getVoiceToTextVocabularyPrompt(germanWord, expectedForeignWord, selectedLanguage, voiceResponse, userName));
            setLastProcessedResponse(voiceResponse);
        } catch (error) {
            console.error('Error getting sentence feedback:', error);
            setResponseSnackbarMessage(`Fehler beim Analysieren der Antwort.`);
            setOpenResponseSnackbar(true);
        } finally {
            setIsProcessingResponse(false);
        }
    }, [voiceResponse, getResponse, language.language, filteredVocabPairs, vocabIndex, userName, isProcessingResponse, isVoiceResponseProcessed, isNewRecording]);

    useEffect(() => {
        if (voiceResponse && !isProcessingResponse && !isVoiceResponseProcessed && isNewRecording) {
            if (processingTimeoutRef.current) {
                clearTimeout(processingTimeoutRef.current);
            }
            
            processingTimeoutRef.current = setTimeout(() => {
                console.log("Initiating sentence feedback for:", voiceResponse);
                getSentenceFeedback();
            }, 1000); // 1 second delay
        }

        return () => {
            if (processingTimeoutRef.current) {
                clearTimeout(processingTimeoutRef.current);
            }
        };
    }, [voiceResponse, getSentenceFeedback, isProcessingResponse, isVoiceResponseProcessed, isNewRecording]);

    const handleButtonClick = () => {
        if (isAudioRecording) {
            recorder.stop();
            setIsAudioRecording(false);
            setIsNewRecording(true);
            console.log("Recording stopped");
        } else {
            startRecording();
            setIsAudioRecording(true);
            setShowAiResponse(true);
            resetState();
            console.log("Recording initiated");
        }
    };

    const handleKnownFilterChange = (event) => {
        setKnownFilter(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleWordSelect = (event) => {
        setSelectedWord(event.target.value);
        const index = filteredVocabPairs.findIndex(pair => pair.german === event.target.value);
        if (index !== -1) {
            setVocabIndex(index);
            resetState();
        }
    };

    const toggleBlur = () => {
        setIsBlurred(!isBlurred);
    };

    const toggleAdditionalInfoBlur = () => {
        setIsAdditionalInfoBlurred(!isAdditionalInfoBlurred);
    };

    const handleSwitchChange = (event) => {
        setIsPlayTextEnabled(event.target.checked);
    };

    const handleCloseResponseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenResponseSnackbar(false);
    };

    useEffect(() => {
        console.log('Voice Response:', voiceResponse);
        console.log('Is Processing Response:', isProcessingResponse);
        console.log('Last Processed Response:', lastProcessedResponse);
        console.log('Is Voice Response Processed:', isVoiceResponseProcessed);
        console.log('Is New Recording:', isNewRecording);
    }, [voiceResponse, isProcessingResponse, lastProcessedResponse, isVoiceResponseProcessed, isNewRecording]);

    if (!language) {
        return <LinearProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">Fehler beim Laden der Vokabelliste: {error.message}</Typography>;
    }

    if (!vocabPairs) {
        return <LinearProgress />;
    }

    return (
        <Container>
            <Box mt={4} display="flex" flexDirection="column" alignItems="center">
                <FormControlLabel
                    control={<Switch checked={isPlayTextEnabled} onChange={handleSwitchChange} />}
                    label={isPlayTextEnabled ? <VolumeUpIcon /> : <VolumeOffIcon />}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Nach Bekanntheitsgrad filtern</InputLabel>
                            <Select value={knownFilter} onChange={handleKnownFilterChange}>
                                <MenuItem value="all">Alle</MenuItem>
                                <MenuItem value="0">Noch nie gewusst</MenuItem>
                                <MenuItem value="1">Einmal gewusst</MenuItem>
                                <MenuItem value="2">Zweimal gewusst</MenuItem>
                                <MenuItem value="3">Dreimal oder öfter gewusst</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            value={searchTerm}
                            onChange={handleSearchChange}
                            label="Wörter suchen"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Wort auswählen</InputLabel>
                            <Select value={selectedWord} onChange={handleWordSelect}>
                                {filteredVocabPairs.map((pair, index) => (
                                    <MenuItem key={index} value={pair.german}>{pair.german} - {pair.foreign}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {filteredVocabPairs[vocabIndex] && (
                            <Card elevation={2}>
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{ fontWeight: 'medium', mb: 1 }}>
                                        Spreche einen Satz und verwende das Trainingswort: <strong>{filteredVocabPairs[vocabIndex].german}</strong>
                                    </Typography>
                                    <Typography 
                                        variant="body1" 
                                        sx={{ 
                                            mb: 1, 
                                            cursor: 'pointer',
                                            filter: isBlurred ? 'blur(5px)' : 'none',
                                            transition: 'filter 0.3s ease',
                                            '&:hover': {
                                                filter: 'blur(3px)',
                                            },
                                        }}
                                        onClick={toggleBlur}
                                    >
                                        {filteredVocabPairs[vocabIndex].foreign}
                                    </Typography>
                                    {filteredVocabPairs[vocabIndex].additionalInfo && (
                                        <Typography 
                                            variant="body2" 
                                            sx={{ 
                                                mb: 1,
                                                cursor: 'pointer',
                                                filter: isAdditionalInfoBlurred ? 'blur(5px)' : 'none',
                                                transition: 'filter 0.3s ease',
                                                '&:hover': {
                                                    filter: 'blur(3px)',
                                                },
                                            }}
                                            onClick={toggleAdditionalInfoBlur}
                                        >
                                            {filteredVocabPairs[vocabIndex].additionalInfo}
                                        </Typography>
                                    )}
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <Chip
                                            label={`${filteredVocabPairs[vocabIndex].knownCounter} mal gewusst`}
                                            size="small"
                                            sx={{ mr: 1 }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleButtonClick}
                                            startIcon={isAudioRecording ? <StopIcon /> : <MicIcon />}
                                        >
                                            {isAudioRecording ? 'Aufnahme stoppen' : 'Aufnahme starten'}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNextVocab}
                                        >
                                            Nächste Vokabel
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                </Grid>
                {showAiResponse && voiceResponse && (
                    <Box mt={2} mb={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1" style={{ marginBottom: '10px' }}>
                                    Deine Antwort: {voiceResponse}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )}
                {currentStreamResponse && (
                    <Box mt={2} mb={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1" component="div">
                                    KI Trainer (streaming): 
                                    <ReactMarkdown>{currentStreamResponse}</ReactMarkdown>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )}
                {isResponseReady && response && (
                    <Box mt={2} mb={2}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1" component="div">
                                    KI Trainer: 
                                    <ReactMarkdown>{response}</ReactMarkdown>
                                    {isStreaming && (
                                        <Box display="flex" alignItems="center" mt={1}>
                                            <CircularProgress size={20} style={{ marginRight: '10px' }} />
                                            Audio wird gestreamt...
                                        </Box>
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </Box>
            <Snackbar open={openResponseSnackbar} autoHideDuration={6000} onClose={handleCloseResponseSnackbar}>
                <MuiAlert onClose={handleCloseResponseSnackbar} severity="info" sx={{ width: '100%' }}>
                    {responseSnackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Container>
    );
}