import { useState, useEffect, useCallback } from 'react';
import { doc, onSnapshot, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

export const useScoreUpdate = (userId) => {
  const [dayScore, setDayScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [lastUpdateDate, setLastUpdateDate] = useState(null);

  const checkAndUpdateScore = useCallback(async () => {
    if (!userId) return;

    const userStatsRef = doc(db, 'userStats', userId);
    
    try {
      const docSnapshot = await onSnapshot(userStatsRef, async (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const currentLastUpdateDate = data.lastUpdate ? data.lastUpdate.toDate() : new Date(0);
          const today = new Date();
          
          if (lastUpdateDate === null || 
              currentLastUpdateDate.getDate() !== lastUpdateDate.getDate() || 
              currentLastUpdateDate.getMonth() !== lastUpdateDate.getMonth() || 
              currentLastUpdateDate.getFullYear() !== lastUpdateDate.getFullYear()) {
            
            if (currentLastUpdateDate.getDate() !== today.getDate() ||
                currentLastUpdateDate.getMonth() !== today.getMonth() ||
                currentLastUpdateDate.getFullYear() !== today.getFullYear()) {
              await updateDoc(userStatsRef, {
                dayScore: 0,
                lastUpdate: serverTimestamp()
              });
              setDayScore(0);
            } else {
              setDayScore(data.dayScore || 0);
            }

            setLastUpdateDate(currentLastUpdateDate);
          } else {
            setDayScore(data.dayScore || 0);
          }
          
          setTotalScore(data.totalScore || 0);
        } else {
          const newData = {
            dayScore: 0,
            totalScore: 0,
            lastUpdate: serverTimestamp()
          };
          await setDoc(userStatsRef, newData);
          setDayScore(0);
          setTotalScore(0);
          setLastUpdateDate(new Date());
        }
      });

      return () => docSnapshot();
    } catch (error) {
      console.error('Error checking and updating score:', error);
    }
  }, [userId, lastUpdateDate]);

  return { dayScore, totalScore, checkAndUpdateScore };
};