import { useState, useEffect } from 'react';
import { useCollection } from './useCollection';

export function useVocabList(userId, currentLanguage) {
    const [vocabPairs, setVocabPairs] = useState([]);
    const [error, setError] = useState(null);

    const languageId = currentLanguage ? currentLanguage.id : null;
    const { documents, error: collectionError } = useCollection('vocabList', userId, languageId);

    //console.log('userId:', userId); // log userId
    //console.log('currentLanguage:', currentLanguage); // log currentLanguage
    //console.log('documents:', documents); // log documents
    //console.log('collectionError:', collectionError); // log collectionError

    useEffect(() => {
        if (documents) {
            setVocabPairs(documents);
        }
        if (collectionError) {
            setError(collectionError);
        }
    }, [documents, collectionError, currentLanguage]);

    return { vocabPairs, error };
}