import { useState, useEffect } from 'react';
import OpenAI from 'openai';

const useOpenAIChat = () => {
    const [response, setResponse] = useState('');
    const [streamResponse, setStreamResponse] = useState('');
    const [messages, setMessages] = useState([
        { role: "system", content: "Du bist ein Experte für Vokabeltraining und hilfst deinem Schüler besser zu lernen, gemacht Fehler zu verstehen und diesen Fehler in Zukunft zu vermeiden." }
    ]);

    const openai = new OpenAI({ 
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true 
    });

    const addMessage = (role, content) => {
        setMessages(prevMessages => [...prevMessages, { role, content }]);
    };

    const getResponse = async (prompt) => {
        try {
            //console.log('getResponse called with prompt:', prompt);
            addMessage("user", prompt);

            // Bereite die Nachrichten für den Versand vor
            const messagesToSend = [...messages, { role: "user", content: prompt }];

            //console.log('Sending messages to OpenAI:', messagesToSend);

            const stream = await openai.chat.completions.create({
                messages: messagesToSend,
                model: "gpt-4o",
                stream: true,
            });

            //console.log('Stream received from OpenAI');

            let fullResponse = ''; // Eine temporäre Variable zum Speichern der vollständigen Antwort

            for await (const chunk of stream) {
                if (chunk.choices[0]?.delta?.content) {
                    const content = chunk.choices[0].delta.content;
                    //console.log('Received chunk:', content);
                    setStreamResponse(prevStream => prevStream + content);
                    fullResponse += content;
                }
            }

            //console.log('Full response received:', fullResponse);

            // Setze die endgültige vollständige Antwort, nachdem der Stream abgeschlossen ist
            setResponse(fullResponse);
            addMessage("assistant", fullResponse);

            return fullResponse;  // Return the full response

        } catch (error) {
            //console.error('Error fetching response from OpenAI:', error);
            throw error;  // Re-throw the error to be caught in the calling function
        }
    };

    // Effekt zum Löschen der Antwort beim Unmount
    useEffect(() => {
        return () => {
            setResponse('');
            setStreamResponse('');
        };
    }, []);

    return { response, streamResponse, getResponse, addMessage };
};

export default useOpenAIChat;