import { useState } from 'react';
import OpenAI from 'openai';

const useAiVoiceInput = () => {
    const [voiceResponse, setVoiceResponse] = useState('');

    const openai = new OpenAI({ 
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true 
    });

    const getVoiceResponse = async (audioFile) => {
        try {
            const transcription = await openai.audio.transcriptions.create({
                model: "whisper-1",
                file: audioFile,
            });
            setVoiceResponse(transcription.text);
        } catch (error) {
            console.error('Error fetching response from OpenAI:', error);
        }
    };

    return { voiceResponse, getVoiceResponse };
};

export default useAiVoiceInput;