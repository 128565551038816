import { db } from '../firebase/config';
import { doc, deleteDoc } from 'firebase/firestore';

export function useDeleteVocab() {
  const deleteVocab = async (docId) => {
    const vocabRef = doc(db, 'vocabList', docId);

    await deleteDoc(vocabRef);
  };

  return {
    deleteVocab
  };
}