import { useCallback } from 'react';
import { auth, db } from '../firebase/config';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import useOpenAIChat from './useOpenAI';

const errorCategories = [
  { code: 'PGK', name: 'Phonem-Graphem-Korrespondenz' },
  { code: 'PGK-V', name: 'Verwechslung von Vokalen' },
  { code: 'PGK-K', name: 'Verwechslung von Konsonanten' },
  { code: 'MP', name: 'Morphologische Prinzipien' },
  { code: 'MP-WB', name: 'Fehler bei der Wortbildung' },
  { code: 'MP-FL', name: 'Falsche Flexion' },
  { code: 'OR', name: 'Orthographische Regeln' },
  { code: 'OR-RS', name: 'Rechtschreibregeln' },
  { code: 'OR-DP', name: 'Doppelkonsonanten' },
  { code: 'OR-UE', name: 'Umlaute und \'ß\'' },
  { code: 'SS', name: 'Silbenstruktur' },
  { code: 'SS-SB', name: 'Silbentrennung bei Worttrennung' },
  { code: 'AS', name: 'Akzente und Sonderzeichen' },
  { code: 'AS-AK', name: 'Akzentsetzung' },
  { code: 'AS-SZ', name: 'Sonderzeichen' },
  { code: 'FS', name: 'Fremdwortschreibung' },
  { code: 'FS-E', name: 'Eingedeutschte Fremdwörter' },
  { code: 'FS-U', name: 'Unveränderte Fremdwörter' },
  { code: 'ZG', name: 'Zusammen- und Getrenntschreibung' },
  { code: 'ZG-ZS', name: 'Zusammenschreibung' },
  { code: 'ZG-GS', name: 'Getrenntschreibung' },
  { code: 'IP', name: 'Interpunktion' },
  { code: 'IP-K', name: 'Kommasetzung' },
  { code: 'IP-P', name: 'Punktsetzung' },
  { code: 'HV', name: 'Homophone und verwechselte Wörter' },
  { code: 'HV-H', name: 'Homophone' },
  { code: 'HV-VW', name: 'Verwechselte Wörter' },
  { code: 'GF', name: 'Grammatikalische Fehler' },
  { code: 'GF-AT', name: 'Artikel' },
  { code: 'GF-PR', name: 'Präpositionen' },
  { code: 'SF', name: 'Semantische Fehler' },
  { code: 'SF-SB', name: 'Bedeutungsverwechslungen' },
  { code: 'TF', name: 'Typographische Fehler' },
  { code: 'TF-T', name: 'Tippfehler' }
];

const validErrorCodes = new Set(errorCategories.map(cat => cat.code));

export function useErrorAnalysis() {
  const { getResponse } = useOpenAIChat();

  const extractAndValidateErrorCodes = (analysis) => {
    if (!analysis || analysis.trim() === '' || analysis.trim() === 'NA') {
      return [];
    }
    
    const codes = analysis.split(';').map(code => code.trim());
    return codes.filter(code => validErrorCodes.has(code));
  };

  const analyzeAndStoreError = useCallback(async (vocabId, correctWord, userAnswer, isReverseOrder) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        throw new Error('User not authenticated');
      }

      const prompt = isReverseOrder
        ? `
          Analyze the following spelling error:
          Correct word (German): ${correctWord}
          User's answer (Foreign): ${userAnswer}

          Categorize the error into one of the following categories:
          ${errorCategories.map(cat => `${cat.code}: ${cat.name}`).join('\n')}

          Please only provide the error code. If there are more than one error code, please separate them by semicolon:
          [Code];[Code]
          
          If none of the above categories match please only answer with NA, like this:
          NA
          
          Since there will be a post-processing of this reply, make sure to don't add anything to the error codes, no explanation, no introduction, nothing else. Doing so would result in ignoring this analysis.
        `
        : `
          Analyze the following spelling error:
          Correct word (Foreign): ${correctWord}
          User's answer (German): ${userAnswer}

          Categorize the error into one of the following categories:
          ${errorCategories.map(cat => `${cat.code}: ${cat.name}`).join('\n')}

          Please only provide the error code. If there are more than one error code, please separate them by semicolon:
          [Code];[Code]
          
          If none of the above categories match please only answer with NA, like this:
          NA
          
          Since there will be a post-processing of this reply, make sure to don't add anything to the error codes, no explanation, no introduction, nothing else. Doing so would result in ignoring this analysis.
        `;

      const errorAnalysis = await getResponse(prompt);
      
      if (!errorAnalysis) {
        console.error('No response received from OpenAI');
        return 'NA';
      }
      
      const validErrorCodes = extractAndValidateErrorCodes(errorAnalysis);
      
      if (validErrorCodes.length > 0) {
        const vocabRef = doc(db, 'vocabList', vocabId);
        const today = new Date().toISOString().split('T')[0];
        const direction = isReverseOrder ? 'foreign_to_de' : 'de_to_foreign';

        const vocabDoc = await getDoc(vocabRef);
        let updateData = {};

        if (vocabDoc.exists()) {
          const currentData = vocabDoc.data();
          const errors = currentData.errors || {};
          const directionErrors = errors[direction] || {};

          validErrorCodes.forEach(code => {
            if (!directionErrors[code]) {
              directionErrors[code] = [];
            }
            directionErrors[code].push({
              date: today,
              incorrectAnswer: userAnswer
            });
          });

          errors[direction] = directionErrors;
          updateData = { errors: errors };
        } else {
          updateData = {
            errors: {
              [direction]: Object.fromEntries(validErrorCodes.map(code => [code, [{
                date: today,
                incorrectAnswer: userAnswer
              }]]))
            }
          };
        }

        try {
          await updateDoc(vocabRef, updateData);
        } catch (updateError) {
          console.error('Error updating Firestore document:', updateError);
          throw updateError;
        }
      }
      
      return errorAnalysis;
    } catch (error) {
      console.error('Error in analyzeAndStoreError:', error);
      throw error;
    }
  }, [getResponse]);

  return { analyzeAndStoreError };
}
