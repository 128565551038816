import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase/config'; // Import your Firebase auth object

// Create the context
export const CurrentUserContext = createContext();

// Create a provider component
export function CurrentUserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

// Create a custom hook for using the current user context
export function useCurrentUser() {
  return useContext(CurrentUserContext);
}