import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/config';
import VocabInput from '../components/VocabInput';
import VocabList from '../components/VocabList';
import LanguageManager from '../components/LanguageManager';
import TrainingPage from '../components/TrainingPage';
import { CurrentUserProvider } from '../contexts/CurrentUserContext';
import { TrainingProvider } from '../contexts/TrainingsContext';
import { Container, Typography, Paper, Grid, Button, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LearningPage from '../components/LearningPage';
import ConversationTraining from '../components/ConversationTraining';
import GrammarTraining from '../components/GrammarTraining';
import { useRecording } from '../contexts/RecordingContext';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ReplayIcon from '@mui/icons-material/Replay';
import MicIcon from '@mui/icons-material/Mic';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { useScoreUpdate } from '../hooks/useScoreUpdate';
import { setCurrentUser } from '../redux/userSlice';

function UnauthenticatedView() {
  function FeatureCard({ title, description }) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" component="h2">{title}</Typography>
          <Typography>{description}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h4" component="h1" style={{ textAlign: 'center', margin: '20px 0' }}>
        Willkommen bei deinem intelligenten Vokabeltrainer!
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            title="Freie Sprachenwahl und Kategorien"
            description="Füge beliebige Sprachen oder Kategorien hinzu und personalisiere deine Lernerfahrung."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            title="Vokabeln einfach hinzufügen"
            description="Gib Vokabeln manuell ein oder nutze den Massenimport per Copy/Paste aus Excel."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            title="Lernmodus"
            description="Lerne neue Vokabeln in kleinen Gruppen, Gruppengröße frei bestimmbar je nach Konzentrationsfähigkeit."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            title="Wiederholungsmodus"
            description="Vokabeln werden in immer längeren Abständen wiederholt, bei Fehlern häufiger zur effizienten Festigung."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            title="AI Trainer"
            description="Ein AI Trainer analysiert gemachte Fehler und gibt personalisierte Tipps zur Verbesserung."
          />
        </Grid>
      </Grid>
      <div style={{ textAlign: 'center', margin: '40px 0' }}>
        <Button variant="contained" color="primary" size="large" component={Link} to="/signup">
          Jetzt starten!
        </Button>
      </div>
    </div>
  );
}

export default function Home() {
  const [user, setUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const { isRecording, setIsRecording } = useRecording();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const language = useSelector(state => state.language);
  const isSubjectTopic = language.isSubjectTopic;
  const { checkAndUpdateScore } = useScoreUpdate(user?.uid);
  const dispatch = useDispatch();

  const mediaStreamRef = useRef(null);
  const recorderRef = useRef(null);

  const startRecording = useCallback(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaStreamRef.current = stream;
        const newRecorder = new MediaRecorder(stream);
        recorderRef.current = newRecorder;
        newRecorder.start();
        setIsRecording(true);
      })
      .catch(error => {
        console.error("Error accessing the microphone", error);
      });
  }, [setIsRecording]);
  
  const stopRecording = useCallback(() => {
    if (recorderRef.current) {
      recorderRef.current.stop();
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
    }
    setIsRecording(false);
  }, [setIsRecording]);

  useEffect(() => {
    const shouldRecord = (tabValue === 3 || tabValue === 4) && !isSubjectTopic;
    
    if (shouldRecord && !isRecording) {
      startRecording();
    } else if (!shouldRecord && isRecording) {
      stopRecording();
    }

    return () => {
      if (isRecording) {
        stopRecording();
      }
    };
  }, [tabValue, isRecording, startRecording, stopRecording, isSubjectTopic]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isRecording) {
        stopRecording();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isRecording, stopRecording]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      dispatch(setCurrentUser(currentUser));
      if (currentUser && currentUser.emailVerified) {
        checkAndUpdateScore();
      }
    });
    return () => unsubscribe();
  }, [checkAndUpdateScore, dispatch]);

  useEffect(() => {
    if (isSubjectTopic && tabValue > 3) {
      setTabValue(3);
    }
  }, [isSubjectTopic]);

  const getValidTabValue = useCallback(() => {
    const maxTabIndex = isSubjectTopic ? 3 : 5;
    return Math.min(tabValue, maxTabIndex);
  }, [isSubjectTopic, tabValue]);

  const handleAccordionChange = (event, isExpanded) => {
    setIsAccordionExpanded(isExpanded);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    checkAndUpdateScore();
  };

  const handleMenuMouseEnter = () => {
    setIsMenuExpanded(true);
  };

  const handleMenuMouseLeave = () => {
    setIsMenuExpanded(false);
  };

  const CollapsibleTab = ({ icon, label, ...props }) => (
    <Tab
      icon={icon}
      label={isMenuExpanded ? label : ''}
      sx={{
        minWidth: isMenuExpanded ? 'auto' : '56px',
        transition: 'all 0.3s',
      }}
      {...props}
    />
  );

  const renderTabs = () => {
    const tabs = [
      <CollapsibleTab key={0} icon={<SettingsIcon />} label="Inhaltsverwaltung" />,
      <CollapsibleTab key={1} icon={<MenuBookIcon />} label="Lernen" />,
      <CollapsibleTab key={2} icon={<ReplayIcon />} label="Wiederholen" />,
    ];

    if (!isSubjectTopic) {
      tabs.push(
        <CollapsibleTab key={3} icon={<MicIcon />} label="Sprachübung" />,
        <CollapsibleTab key={4} icon={<InfoIcon />} label="Grammatik Erklärung" />
      );
    }

    return tabs;
  };

  const renderContent = () => {
    if (!user || !user.emailVerified) {
      return <UnauthenticatedView />;
    }

    const validTabValue = getValidTabValue();
    switch (validTabValue) {
      case 0:
        return (
          <>
            <Accordion expanded={isAccordionExpanded} onChange={handleAccordionChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vocab-input-content"
                id="vocab-input-header"
              >
                <Typography>Vokabeln hinzufügen</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <VocabInput />
              </AccordionDetails>
            </Accordion>
            <VocabList />
          </>
        );
      case 1:
        return <LearningPage />;
      case 2:
        return (
          <TrainingProvider>
            {user && user.displayName && <TrainingPage userName={user.displayName} />}
          </TrainingProvider>
        );
      case 3:
        return (
          <TrainingProvider>
            {user && user.displayName && <ConversationTraining userName={user.displayName} />}
          </TrainingProvider>
        );
      case 4:
        return (
          <TrainingProvider>
            {user && user.displayName && <GrammarTraining userName={user.displayName} />}
          </TrainingProvider>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg">
      <CurrentUserProvider>
        {user && user.emailVerified ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Paper elevation={3} style={{ padding: '20px', margin: '20px 0' }}>
                <Typography variant="h6">Hallo, {user.displayName}!</Typography>
                <Box
                  onMouseEnter={handleMenuMouseEnter}
                  onMouseLeave={handleMenuMouseLeave}
                  sx={{
                    transition: 'all 0.3s',
                    width: isMenuExpanded ? '100%' : 'auto',
                  }}
                >
                  <Tabs
                    value={getValidTabValue()}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons={isMenuExpanded ? 'auto' : false}
                    allowScrollButtonsMobile
                    aria-label="vocab tabs"
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        justifyContent: isMenuExpanded ? 'flex-start' : 'center',
                      },
                    }}
                  >
                    {renderTabs()}
                  </Tabs>
                </Box>
                {renderContent()}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} style={{ padding: '20px', margin: '20px 0' }}>
                <Box mt={2}>
                  <LanguageManager />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          renderContent()
        )}
      </CurrentUserProvider>
    </Container>
  );
}