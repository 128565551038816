import { useCallback } from 'react';
import { useChangeVocab } from '../hooks/firebase/useChangeVocab';

export function useTrainingVocabUpdate() {
  const { changeVocab } = useChangeVocab();

  const calculateNextTraining = useCallback((knownCounter) => {
    const today = new Date();
    let daysToAdd;

    if (knownCounter <= 5) {
      daysToAdd = Math.pow(2, knownCounter - 1);
    } else if (knownCounter <= 8) {
      daysToAdd = 32 * (knownCounter - 5);
    } else {
      daysToAdd = 365;
    }

    const nextDate = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
    return nextDate.toISOString();
  }, []);

  const updateTrainingVocab = useCallback(async (vocab, isCorrect) => {
    let changes;

    if (isCorrect) {
      const newKnownCounter = (vocab.knownCounter || 0) + 1;
      const nextTraining = calculateNextTraining(newKnownCounter);
      changes = {
        knownCounter: newKnownCounter,
        nextTraining: nextTraining
      };
    } else {
      changes = {
        knownCounter: 0,
        nextTraining: new Date().toISOString()
      };
    }

    try {
      await changeVocab(vocab.id, changes);
      return { ...vocab, ...changes };
    } catch (error) {
      console.error('Error updating vocab:', error);
      throw error;
    }
  }, [calculateNextTraining, changeVocab]);

  return { updateTrainingVocab };
}