import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentYear = new Date().getFullYear();

  return (
    <Box component="footer" sx={{ 
      mt: 'auto', 
      py: 3, 
      bgcolor: 'background.paper',
      borderTop: 1,
      borderColor: 'divider'
    }}>
      <Container maxWidth="lg">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'flex-start' : 'center'
        }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: isMobile ? 2 : 0 }}>
            © {currentYear} BrainWizzr
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? 1 : 2
          }}>
            {['AGB', 'Datenschutz', 'Impressum'].map((item) => (
              <Link
                key={item}
                component={RouterLink}
                to={`/${item.toLowerCase()}`}
                color="primary"
                underline="hover"
                sx={{ typography: 'body2' }}
              >
                {item}
              </Link>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;