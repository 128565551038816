import { useEffect, useState } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

export const useCheckScoreDate = (userId) => {
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkDate = async () => {
      if (!userId) {
        setIsChecking(false);
        return;
      }

      setIsChecking(true);
      setError(null);

      try {
        const userStatsRef = doc(db, 'userStats', userId);
        const docSnap = await getDoc(userStatsRef);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (docSnap.exists()) {
          const { dayDate } = docSnap.data();

          const storedDate = dayDate ? dayDate.toDate() : new Date(0);
          storedDate.setHours(0, 0, 0, 0);

          if (storedDate < today) {
            // It's a new day, reset dayScore
            await updateDoc(userStatsRef, {
              dayDate: today,
              dayScore: 0
            });
            console.log('New day detected, dayScore reset');
          } else {
            console.log('Same day, no reset needed');
          }
        } else {
          // Document doesn't exist, create it
          const newStats = {
            dayDate: today,
            dayScore: 0,
            totalScore: 0
          };
          await setDoc(userStatsRef, newStats);
          console.log('User stats document created');
        }
      } catch (err) {
        setError(err);
        console.error('Error checking date:', err);
      } finally {
        setIsChecking(false);
      }
    };

    checkDate();
  }, [userId]);

  return { isChecking, error };
};