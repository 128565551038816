import React, { useState, useCallback, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { useSelector } from 'react-redux';
import useOpenAI from '../hooks/useOpenAI';
import useAiVoiceInput from '../hooks/useAiVoiceInput';
import useOpenAiTextToSpeech from '../hooks/useOpenAiTextToSpeech';
import { useVocabList } from '../hooks/useVocabList';
import { Button, Container, Snackbar, Box, Typography, Grid, Card, CardContent, TextField, CircularProgress } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import MuiAlert from '@mui/material/Alert';
import { getVoiceToTextGrammarPrompt } from '../utils/aiPrompt';
import ReactMarkdown from 'react-markdown';
import SendIcon from '@mui/icons-material/Send';
import { Switch, FormControlLabel } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

export default function GrammarTraining({ userName }) {
  const [user] = useAuthState(auth);
  const language = useSelector(state => state.language.language);
  const { response, streamResponse, getResponse, addMessage } = useOpenAI();
  const { voiceResponse, getVoiceResponse } = useAiVoiceInput();
  const [responseSnackbarMessage, setResponseSnackbarMessage] = useState('');
  const [openResponseSnackbar, setOpenResponseSnackbar] = useState(false);
  const { vocabPairs } = useVocabList(user.uid, language);
  const [vocabIndex, setVocabIndex] = useState(0);
  const [shuffledVocabPairs, setShuffledVocabPairs] = useState([]);
  const { playText, isStreaming } = useOpenAiTextToSpeech();
  const [audioFile, setAudioFile] = useState(null);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [textQuestion, setTextQuestion] = useState(null);
  const [isResponseReady, setIsResponseReady] = useState(false);
  const [isPlayTextEnabled, setIsPlayTextEnabled] = useState(true);
  const [shouldGetFeedback, setShouldGetFeedback] = useState(false);
  const [currentStreamResponse, setCurrentStreamResponse] = useState('');

  const handleSwitchChange = (event) => {
    setIsPlayTextEnabled(event.target.checked);
  };

  useEffect(() => {
    if (streamResponse) {
      setCurrentStreamResponse(streamResponse);
    }
  }, [streamResponse]);

  useEffect(() => {
    if (response) {
      setMessages(prevMessages => [
        { type: 'api', text: response },
        ...prevMessages.filter(msg => msg.type !== 'stream')
      ]);
      setCurrentStreamResponse('');
      setIsResponseReady(true);
      if (isPlayTextEnabled) {
        playText(response);
      }
    }
  }, [response, isPlayTextEnabled, playText]);

  const handleInputSubmit = () => {
    setIsResponseReady(false);
    setTextQuestion(inputValue);
    setMessages(prevMessages => [{ type: 'user', text: inputValue }, ...prevMessages]);
    setInputValue('');
    setShouldGetFeedback(true);
  };

  useEffect(() => {
    if (voiceResponse) {
      setMessages(prevMessages => [{ type: 'user', text: voiceResponse }, ...prevMessages]);
      setShouldGetFeedback(true);
    }
  }, [voiceResponse]);

  const startRecording = useCallback(() => {
    if (recorder && recorder.state === "recording") {
      return;
    }
  
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const newRecorder = new MediaRecorder(stream);
        newRecorder.start();
        newRecorder.ondataavailable = e => {
          const file = new File([e.data], "audio.webm", { type: "audio/webm" });
          setAudioFile(file);
        };
        setRecorder(newRecorder);
      });
  }, [recorder]);

  useEffect(() => {
    if (audioFile) {
      getVoiceResponse(audioFile)
    }
  }, [audioFile, getVoiceResponse]);

  const getSentenceFeedback = useCallback(async () => {
    if (!voiceResponse) return;
    setResponseSnackbarMessage(`KI-Trainer analysiert die Antwort.`);
    setOpenResponseSnackbar(true);
    const selectedLanguage = language;
    
    await getResponse(getVoiceToTextGrammarPrompt(selectedLanguage, voiceResponse, userName));
  }, [voiceResponse, getResponse, language, userName]);

  const getTextFeedback = useCallback(async () => {
    if (!textQuestion) return;
    setResponseSnackbarMessage(`KI-Trainer analysiert die Antwort.`);
    setOpenResponseSnackbar(true);
    const selectedLanguage = language;
    
    await getResponse(getVoiceToTextGrammarPrompt(selectedLanguage, textQuestion, userName));
  }, [textQuestion, getResponse, language, userName]);

  useEffect(() => {
    if (shouldGetFeedback) {
      if (voiceResponse) {
        getSentenceFeedback();
      } else if (textQuestion) {
        getTextFeedback();
      }
      setShouldGetFeedback(false);
    }
  }, [shouldGetFeedback, voiceResponse, textQuestion, getSentenceFeedback, getTextFeedback]);
  
  const handleCloseResponseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenResponseSnackbar(false);
  };

  const handleButtonClick = () => {
    if (isAudioRecording) {
      recorder.stop();
    } else {
      startRecording();
    }
    setIsAudioRecording(prevIsAudioRecording => !prevIsAudioRecording);
  };

  return (
    <Container>
      <div>
        <FormControlLabel
          control={<Switch checked={isPlayTextEnabled} onChange={handleSwitchChange} />}
          label={isPlayTextEnabled ? <VolumeUpIcon /> : <VolumeOffIcon />}
        />
      </div>
      <Box mt={4} display="flex" flexDirection="column" alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs style={{flexGrow: 1}}>
                <TextField
                  value={inputValue}
                  onChange={(event) => setInputValue(event.target.value)}
                  onKeyDown={(event) => event.key === 'Enter' && handleInputSubmit()}
                  placeholder="Stelle hier deine Frage"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleInputSubmit}>
                  <SendIcon />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleButtonClick}
                  startIcon={isAudioRecording ? <StopIcon /> : <MicIcon />}
                  size="large"
                >
                  {isAudioRecording ? 'Stoppe Aufnahme' : 'Starte Aufnahme'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={4} display="flex" flexDirection="column" alignItems="center">
          {currentStreamResponse && (
            <Box alignSelf="flex-end" mb={2}>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="div">
                    KI Trainer (streaming): 
                    <ReactMarkdown>{currentStreamResponse}</ReactMarkdown>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          )}
          {messages.map((message, index) => (
            <Box key={index} alignSelf={message.type === 'user' ? 'flex-start' : 'flex-end'} mb={2}>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="div">
                    {message.type === 'user' ? `${userName}: ` : 'KI Trainer: '}
                    <ReactMarkdown>{message.text}</ReactMarkdown>
                    {message.type === 'api' && isStreaming && (
                      <Box display="flex" alignItems="center" mt={1}>
                        <CircularProgress size={20} style={{ marginRight: '10px' }} />
                        Audio wird gestreamt...
                      </Box>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      </Box>
      <Snackbar open={openResponseSnackbar} autoHideDuration={6000} onClose={handleCloseResponseSnackbar}>
        <MuiAlert onClose={handleCloseResponseSnackbar} severity="info" sx={{ width: '100%' }}>
          {responseSnackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
}