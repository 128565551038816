import React, { useState } from 'react';
import { useSignup } from '../hooks/useSignup';
import { TextField, Button, Card, CardContent, Typography, Container, Snackbar } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";

export default function Signup({ navigateTo }) {
  const { email, setEmail, password, setPassword, error } = useSignup();
  const [displayName, setDisplayName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Senden der E-Mail-Verifizierung und Aktualisierung des Profils parallel
      await Promise.all([
        sendEmailVerification(userCredential.user),
        updateProfile(userCredential.user, { displayName })
      ]);

      setSnackbarMessage('Super, du bist dabei! Schau in deine Mails für den Bestätigungslink.');
      setSnackbarOpen(true);
      
      // Sofortige Weiterleitung zur Login-Seite
      navigateTo('/login');
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/email-already-in-use') {
        setSnackbarMessage('Ups, diese E-Mail ist schon vergeben. Vielleicht willst du dich einloggen?');
      } else {
        setSnackbarMessage('Hoppla! Da ist was schiefgelaufen. Magst du es nochmal versuchen?');
      }
      setSnackbarOpen(true);
    }
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xs">
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>Werde Teil von BrainWizzr!</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Wie sollen wir dich nennen?"
              required
              fullWidth
              variant="outlined"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              margin="normal"
              InputLabelProps={{
                required: false
              }}
            />
            <TextField
              label="Deine E-Mail"
              type="email"
              required
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              InputLabelProps={{
                required: false
              }}
            />
            <TextField
              label="Dein Passwort"
              type="password"
              required
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              InputLabelProps={{
                required: false
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '20px' }}
            >
              Los geht's!
            </Button>
            {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
          </form>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Container>
  );
}