import { useCallback, useState, useEffect } from 'react';
import { useErrorAnalysis } from './useErrorAnalysis';

export const useAnswerChecker = (initialVocabs, isReverseOrder) => {
  const [remainingVocabs, setRemainingVocabs] = useState(initialVocabs);
  const [totalVocabs, setTotalVocabs] = useState(initialVocabs.length);
  const [attempts, setAttempts] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [lastIncorrectAnswer, setLastIncorrectAnswer] = useState(null);
  const [currentVocab, setCurrentVocab] = useState(null);
  const { analyzeAndStoreError } = useErrorAnalysis();

  const checkAnswer = useCallback((inputValue, isReverseOrder) => {
    if (remainingVocabs.length === 0) return null;

    const currentVocab = remainingVocabs[0];
    const isCorrect = isReverseOrder 
      ? inputValue.toLowerCase().trim() === currentVocab.german.toLowerCase().trim()
      : inputValue.toLowerCase().trim() === currentVocab.foreign.toLowerCase().trim();

    setAttempts(prev => prev + 1);

    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1);
      setRemainingVocabs(prevVocabs => prevVocabs.slice(1));
    } else {
      setLastIncorrectAnswer({ vocabId: currentVocab.id, correct: currentVocab.foreign, input: inputValue });
      setRemainingVocabs(prevVocabs => {
        const updatedVocabs = prevVocabs.slice(1);
        updatedVocabs.push(currentVocab);
        return updatedVocabs;
      });
    }

    return {
      isCorrect,
      correctAnswer: currentVocab.foreign,
      nextVocab: remainingVocabs[1]?.german || null,
      progress: {
        correctAnswers: correctAnswers + (isCorrect ? 1 : 0),
        totalVocabs,
        attempts: attempts + 1,
        remainingVocabs: remainingVocabs.length - 1
      }
    };
  }, [remainingVocabs, attempts, correctAnswers, totalVocabs]);

  useEffect(() => {
    if (lastIncorrectAnswer) {
      analyzeAndStoreError(lastIncorrectAnswer.vocabId, lastIncorrectAnswer.correct, lastIncorrectAnswer.input, isReverseOrder)
        .catch(error => console.error('Error analyzing and storing error:', error));
      setLastIncorrectAnswer(null);
    }
  }, [lastIncorrectAnswer, analyzeAndStoreError, isReverseOrder]);

  const resetVocabs = useCallback((newVocabs) => {
    const shuffled = [...newVocabs].sort(() => Math.random() - 0.5);
    setRemainingVocabs(shuffled);
    setTotalVocabs(shuffled.length);
    setAttempts(0);
    setCorrectAnswers(0);
  }, []);

  const moveCurrentVocabToEnd = useCallback(() => {
    setRemainingVocabs(prevVocabs => {
      if (prevVocabs.length <= 1) return prevVocabs;
      const [current, ...rest] = prevVocabs;
      return [...rest, current];
    });
  }, []);

  return {
    checkAnswer,
    resetVocabs,
    moveCurrentVocabToEnd,
    currentVocab: remainingVocabs[0],
    progress: {
      correctAnswers,
      totalVocabs,
      attempts,
      remainingVocabs: remainingVocabs.length
    }
  };
};