import { useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/config';

export function useLogout() {
  const [error, setError] = useState(null);

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      setError(error.message);
    }
  };

  return {
    logout,
    error
  };
}