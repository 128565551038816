import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSubjectTopic: false,
  language: '',
  userId: ''
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      // Kombiniere den bestehenden State mit den neuen Daten
      const newState = { ...state, ...action.payload };
      
      // Stelle sicher, dass isSubjectTopic nur dann true ist,
      // wenn es explizit als true in den Daten gesetzt wurde
      newState.isSubjectTopic = action.payload.isSubjectTopic === true;
      
      return newState;
    }
  }
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;