import { auth } from '../firebase/config';
import { db } from '../firebase/config';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';

export function useAddVocab() {
  const addVocab = async (foreign, german, knownCounter, languageId, nextTraining, additionalInfo) => {
    const userId = auth.currentUser?.uid;

    await addDoc(collection(db, 'vocabList'), { 
      foreign: foreign,
      german: german,
      knownCounter: Number(knownCounter),
      languageId: languageId,
      nextTraining: nextTraining,
      userId: userId,
      additionalInfo: additionalInfo || '' // Add the additional info field
    });
  };

  const checkVocabExists = async (foreign, languageId) => {
    const userId = auth.currentUser?.uid;
    
    const vocabQuery = query(
      collection(db, 'vocabList'),
      where('foreign', '==', foreign),
      where('languageId', '==', languageId),
      where('userId', '==', userId)
    );

    const querySnapshot = await getDocs(vocabQuery);
    
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return {
        id: doc.id,
        ...doc.data()
      };
    }

    return null;
  };

  return {
    addVocab,
    checkVocabExists
  };
}