import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase/config'; // Import the db object from your Firebase config
import { useAuthState } from 'react-firebase-hooks/auth'; // Import the useAuthState hook

const useAddLanguage = () => {
  // Get the current user
  const auth = getAuth();
  const [user] = useAuthState(auth);

  const addLanguage = async (language, isSubjectTopic) => {
    try {
      // Check if the user already has the new language in the database
      const q = query(collection(db, 'languageList'), where('userId', '==', user.uid), where('language', '==', language));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // User already has this language in the database, return a special message
        return { message: 'Sprache oder Sachthema bereits vorhanden.' };
      }

      // Add the new language to the user's language list in the database
      const docRef = await addDoc(collection(db, 'languageList'), {
        userId: user.uid,
        language: language,
        isSubjectTopic: isSubjectTopic
      });

      // Return the added language
      return { id: docRef.id, language: language, isSubjectTopic: isSubjectTopic };
    } catch (error) {
      console.error('Error adding language: ', error);
      throw error;
    }
  };

  return { addLanguage };
};

export default useAddLanguage;
