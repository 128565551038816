import React, { useState, useEffect } from 'react';
import { useUserLanguages } from '../hooks/useUserLanguages';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import useAddLanguage from '../hooks/useAddLanguage';
import useDeleteLanguage from '../hooks/useDeleteLanguage';
import useChangeLanguage from '../hooks/useChangeLanguage';
import { TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography, Checkbox, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TopicIcon from '@mui/icons-material/Topic';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage as setReduxLanguage } from '../redux/languageSlice';

export default function LanguageManager() {
    const dispatch = useDispatch();
    const language = useSelector(state => state.language);
    const [newLanguage, setNewLanguage] = useState('');
    const [isSubjectTopic, setIsSubjectTopic] = useState(false);

    const { addLanguage } = useAddLanguage();
    const deleteLanguage = useDeleteLanguage();

    const [user] = useAuthState(auth);

    const { documents: languages, error } = useUserLanguages('languageList', user ? user.uid : null);

    useEffect(() => {
      if (languages && languages.length > 0) {
        const sortedLanguages = [...languages].sort((a, b) => a.language.localeCompare(b.language));
        dispatch(setReduxLanguage(sortedLanguages[0]));
      }
    }, [languages, dispatch]);
  
    const [addLanguageError, setAddLanguageError] = useState(null);

    const handleAdd = async () => {
      if (newLanguage) {
        const result = await addLanguage(newLanguage, isSubjectTopic);
        if (result.message) {
          setAddLanguageError(result.message);
        } else {
          dispatch(setReduxLanguage(result));
          setNewLanguage('');
          setIsSubjectTopic(false);
        }
      }
    };

    const handleDelete = async (languageId) => {
        try {
            const result = await deleteLanguage(languageId);
            if (language.id === languageId && languages.length > 1) {
                const newCurrentLanguage = languages.find(lang => lang.id !== languageId);
                dispatch(setReduxLanguage(newCurrentLanguage));
            }
        } catch (error) {
            console.error('Error deleting language: ', error);
        }
    };

    const changeLanguage = useChangeLanguage();
    const [isEditing, setIsEditing] = useState(false);
    const [editedLanguage, setEditedLanguage] = useState('');

    const handleEdit = async (languageId) => {
        if (isEditing) {
            try {
                const result = await changeLanguage(languageId, editedLanguage);
                setIsEditing(false);
                setEditedLanguage('');
                const updatedLanguage = { ...language, language: editedLanguage };
                dispatch(setReduxLanguage(updatedLanguage));
            } catch (error) {
                console.error('Error changing language: ', error);
            }
        } else {
            setIsEditing(true);
            const languageToEdit = languages.find(lang => lang.id === languageId);
            setEditedLanguage(languageToEdit.language);
            dispatch(setReduxLanguage(languageToEdit));
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditedLanguage('');
    };
  
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                <TextField 
                    label="Neue Sprache hinzufügen" 
                    value={newLanguage} 
                    onChange={(e) => setNewLanguage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleAdd();
                            e.preventDefault();
                        }
                    }}
                    variant="outlined"
                    fullWidth
                />
                <Tooltip title="Sachthema">
                    <Checkbox
                        icon={<TopicIcon />}
                        checkedIcon={<TopicIcon />}
                        checked={isSubjectTopic}
                        onChange={(e) => setIsSubjectTopic(e.target.checked)}
                        style={{ marginLeft: 8 }}
                    />
                </Tooltip>
            </div>
            {addLanguageError && <Typography color="error">{addLanguageError}</Typography>}
            <Button variant="contained" color="primary" onClick={handleAdd} startIcon={<AddIcon />} style={{ marginTop: 8 }}>
                Hinzufügen
            </Button>
            <List>
        {languages && languages.map((languageItem) => (
            <ListItem 
                key={languageItem.id} 
                button 
                onClick={() => {
                    if (!isEditing) {
                        dispatch(setReduxLanguage(languageItem));
                    }
                }}
            >
                {isEditing && languageItem.id === language.id ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField 
                        value={editedLanguage} 
                        onChange={(e) => setEditedLanguage(e.target.value)}
                    />
                    <div>
                        <IconButton edge="end" aria-label="save" onClick={() => handleEdit(languageItem.id)}>
                            <SaveIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="cancel" onClick={handleCancelEdit}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                </div>
                ) : (
                    <>
                    <ListItemText 
                        primary={languageItem.language} 
                        primaryTypographyProps={{ 
                            color: languageItem.id === language.id ? 'primary' : 'initial' 
                        }}
                    />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(languageItem.id)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(languageItem.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                    </>
                )}
            </ListItem>
        ))}
    </List>
        </div>
    );
}