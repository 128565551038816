import React, { useState, useEffect } from 'react';
import { useAddVocab } from '../hooks/useAddVocab';
import useAiTranslation from '../hooks/useAiTranslation';
import { useSelector } from 'react-redux';
import { TextField, Button, Grid, Box, Switch, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Popover, Typography, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';  

export default function VocabInput() {
    const [germanWord, setGermanWord] = useState('');
    const [foreignWord, setForeignWord] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const { addVocab, checkVocabExists } = useAddVocab();
    const language = useSelector(state => state.language);
    const { response, getResponse } = useAiTranslation();
    const [isAiTranslationActive, setIsAiTranslationActive] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [existingWord, setExistingWord] = useState(null);
    const [isThreeColumnMode, setIsThreeColumnMode] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [importedVocabs, setImportedVocabs] = useState([]);
    const [openImportDialog, setOpenImportDialog] = useState(false);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const importInstructions = (
        <Box p={2}>
            <Typography variant="h6" gutterBottom>Massenimport-Anleitung:</Typography>
            <Typography variant="body1" paragraph>
                1. Öffnen Sie Excel und befüllen Sie zwei oder drei Spalten:
                   A: Deutsch, B: Fremdsprache, C: Zusatzinfo (optional)
            </Typography>
            <Typography variant="body1" paragraph>
                2. Markieren und kopieren Sie die befüllten Zellen (ohne Überschriften).
            </Typography>
            <Typography variant="body1" paragraph>
                3. Kehren Sie zu dieser Seite zurück und klicken Sie auf "Massenimport".
            </Typography>
            <Typography variant="body1">
                Beispiel in Excel:
                <br />
                A1: Hund | B1: dog | C1: Haustier
                <br />
                A2: Katze | B2: cat
            </Typography>
        </Box>
    );

    const handleAdd = async () => {
        const existingVocab = await checkVocabExists(foreignWord, language.id);
        if (existingVocab) {
            setExistingWord(existingVocab);
            setOpenDialog(true);
        } else {
            await addVocabToDatabase();
        }
    };

    const addVocabToDatabase = async () => {
        const nowGMT = new Date().toISOString();
        await addVocab(foreignWord, germanWord, '0', language.id, nowGMT, additionalInfo);
        setGermanWord('');
        setForeignWord('');
        setAdditionalInfo('');
    };

    const handleConfirmAdd = async () => {
        await addVocabToDatabase();
        setOpenDialog(false);
    };

    const handleCancelAdd = () => {
        setOpenDialog(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAdd();
        }
    };

    const handleAiTranslationChange = (event) => {
        setIsAiTranslationActive(event.target.checked);
    };

    const handleColumnModeChange = (event) => {
        setIsThreeColumnMode(event.target.checked);
    };

    const handleGermanBlur = async () => {
        if (germanWord && isAiTranslationActive) {
            let foreignPrompt, zusatzinfoPrompt;

            if (language.isSubjectTopic) {
                foreignPrompt = `Basierend auf dem Thema "${language.language}" und dem Stichwort/der Frage "${germanWord}", generiere eine prägnante und fachlich korrekte Antwort. Die Antwort sollte direkt und informativ sein, ohne Einleitungen wie 'Die Antwort lautet:'. Beschränke dich auf maximal 2-3 Sätze, es sei denn, eine ausführlichere Erklärung ist unbedingt notwendig.`;
                zusatzinfoPrompt = `Generiere eine prägnante Zusatzinformation für den Begriff "${germanWord}" im Kontext des Sachthemas "${language.language}". 
Berücksichtige dabei beispielsweise folgende Aspekte:
- Definition: Gib eine kurze, präzise Definition.
- Kontext: Erkläre kurz, in welchem Zusammenhang der Begriff relevant ist.
- Wichtige Merkmale: Liste 2-3 Hauptmerkmale oder -eigenschaften auf.
- Verwandte Begriffe: Nenne 1-2 eng verwandte Konzepte oder Begriffe.
- Anwendung/Beispiel: Gib ein kurzes, konkretes Anwendungsbeispiel.

Wähle jedoch nur eines der beispielhaften Aspekte aus, und zwar den Aspekt, der für das entsprechende Sachthema und das konkrete Stichwort am sinnvollsten ist. Formatiere die Antwort kompakt und ohne Einleitung, ähnlich einem Lexikoneintrag. Beschränke dich auf die wesentlichen Informationen und verwende Stichpunkte oder Kurzsätze. Die gesamte Antwort sollte nicht länger als eine Zeilen sein.`;
            } else {
                foreignPrompt = `Übersetzte das Wort ${germanWord} in die Sprache ${language.language}. Niemals mit "Das Wort "xy" auf SpracheXY übersetzt lautet "xyz"." antworten. Gebe lediglich die genaue übersetzung aus, ohne Erklärungen oder Einleitungen. Weiterhin sollst Du kurze oder Antworten mit einem Wort bevorzugen, sofern sinnvoll. Zum Beispiel die Frage "weiblisches Wildschwein" sollte die Antwort haben "Bache", und nicht "Bachen sind weiblische Wildschweine". Ebenfalls soll kein Satzzeichen am Ende gesetzt werden, wenn es sich nicht um einen vollständigen Satz handelt.`;
                zusatzinfoPrompt = language.language === 'Latein' 
                    ? `Generiere detaillierte lateinische Zusatzinformationen für das Wort "${germanWord}" basierend auf seiner Wortart:

Für Substantive: Gib den Genitiv Singular, das grammatikalische Geschlecht und besondere Pluralformen an.

Für Verben: Liste die vier Grundformen (Präsens, Infinitiv Präsens, Perfekt, Supinum) auf.

Für Adjektive: 
- Gib den Genitiv Singular an (z.B. Gen. divitis)
- Füge den Ablativ Singular mit Endung hinzu (z.B. Abl. Sg. -e)
- Gib besondere Nominativ Plural Formen an, insbesondere für Neutra (z.B. Nom. Pl. n. -a)
- Füge den Genitiv Plural hinzu (z.B. Gen. Pl. -um)
- Bei unregelmäßiger Deklination oder Besonderheiten ergänze relevante Informationen

Für Zahlwörter und Pronomen: Gib die vollständige Deklination oder besondere Formen an.

Für Adverbien: Kennzeichne es als Adverb und gib Steigerungsformen an, falls vorhanden.

Formatiere die Antwort kompakt und ohne Einleitung, ähnlich wie in einem Wörterbuch. Beschränke dich auf die wesentlichen grammatikalischen Informationen. Antworte in einer Zeile, also ohne Zeilenumbrüche, und formatiere deine Antwort gut leserlich. Wiederhole nicht das deutsche oder lateinische Wort, sondern gib nur die Zusatzinformationen an.`
                    : `Gib zusätzliche Informationen zum Wort "${germanWord}" in der Sprache ${language.language}. Inkludiere folgende Aspekte, sofern relevant:

1. Wortart
2. Genus (bei Substantiven)
3. Pluralform (bei Substantiven)
4. Komparativ und Superlativ (bei Adjektiven)
5. Wichtige Konjugationsformen (bei Verben)
6. Besondere grammatikalische oder semantische Merkmale

Formatiere die Antwort kompakt, ohne Füllwörter und ohne benenne die Kategorien mit einer einduetigen Abkürzung. Trenne die Informationen durch Kommata oder Semikola. Antworte in einer Zeile, also ohne Zeilenumbrüche. Lasse irrelevante Kategorien weg. Stelle sicher, dass deine Antwort in der Sprache ${language.language} ist, und nicht in Deutsch. Kein Satzzeichen am Ende`;
            }

            try {
                const foreignResponse = await getResponse(foreignPrompt);
                setForeignWord(foreignResponse);

                const zusatzinfoResponse = await getResponse(zusatzinfoPrompt);
                setAdditionalInfo(zusatzinfoResponse);
            } catch (error) {
                console.error('Error getting AI response:', error);
                // Optionally, you can set an error state here to display to the user
            }
        }
    };

    const handleForeignBlur = () => {
        if (!foreignWord && germanWord && isAiTranslationActive) {
            handleGermanBlur();
        }
    };

    const handleAdditionalInfoBlur = () => {
        if (!additionalInfo && germanWord && isAiTranslationActive) {
            handleGermanBlur();
        }
    };

    const handlePasteImport = async () => {
        const pastedData = await navigator.clipboard.readText();
        const rows = pastedData.split('\n').map(row => {
            const [germanWord, foreignWord, ...additionalInfoParts] = row.split('\t');
            return {
                germanWord: germanWord ? germanWord.trim() : '',
                foreignWord: foreignWord ? foreignWord.trim() : '',
                additionalInfo: additionalInfoParts.join('\t').trim()
            };
        }).filter(vocab => vocab.germanWord && vocab.foreignWord);

        setImportedVocabs(rows);
        setOpenImportDialog(true);
    };

    const handleImportConfirm = async () => {
        const nowGMT = new Date().toISOString();
        for (const vocab of importedVocabs) {
            await addVocab(
                vocab.foreignWord,
                vocab.germanWord,
                '0',
                language.id,
                nowGMT,
                vocab.additionalInfo || undefined
            );
        }
        setOpenImportDialog(false);
        setImportedVocabs([]);
    };

    const handleImportCancel = () => {
        setOpenImportDialog(false);
        setImportedVocabs([]);
    };

    const handleVocabChange = (index, field, value) => {
        const updatedVocabs = [...importedVocabs];
        updatedVocabs[index][field] = value;
        setImportedVocabs(updatedVocabs);
    };

    const handleVocabDelete = (index) => {
        const updatedVocabs = importedVocabs.filter((_, i) => i !== index);
        setImportedVocabs(updatedVocabs);
    };

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isAiTranslationActive}
                                    onChange={handleAiTranslationChange}
                                    name="aiTranslationSwitch"
                                    color="primary"
                                />
                            }
                            label="KI Übersetzung"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isThreeColumnMode}
                                    onChange={handleColumnModeChange}
                                    name="columnModeSwitch"
                                    color="primary"
                                />
                            }
                            label="Drei Spalten Modus"
                        />
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={handlePasteImport}
                                disableElevation
                                style={{ marginRight: '8px' }}
                            >
                                Massenimport
                            </Button>
                            <Button
                                size="small"
                                onClick={handlePopoverOpen}
                                startIcon={<HelpOutlineIcon />}
                            >
                                Hilfe
                            </Button>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Paper elevation={3}>
                                    {importInstructions}
                                </Paper>
                            </Popover>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={isThreeColumnMode ? 4 : 4.5}>
                    <TextField 
                        fullWidth 
                        label={language.isSubjectTopic ? "Begriff" : "Wort auf Deutsch"}
                        variant="outlined" 
                        value={germanWord} 
                        onChange={(e) => setGermanWord(e.target.value)}
                        onBlur={handleGermanBlur}
                    />
                </Grid>
                <Grid item xs={12} sm={isThreeColumnMode ? 4 : 4.5}>
                    <TextField 
                        fullWidth 
                        label={language.isSubjectTopic ? "Definition/Erklärung" : `Wort auf ${language.language}`}
                        variant="outlined" 
                        value={foreignWord} 
                        onChange={(e) => setForeignWord(e.target.value)}
                        onBlur={handleForeignBlur}
                        onKeyDown={handleKeyDown}
                    />
                </Grid>
                {isThreeColumnMode && (
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            fullWidth 
                            label="Zusatzinfo"
                            variant="outlined" 
                            value={additionalInfo} 
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                            onBlur={handleAdditionalInfoBlur}
                            onKeyDown={handleKeyDown}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={isThreeColumnMode ? 12 : 3}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleAdd}
                            disableElevation
                        >
                            <AddIcon />
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={openDialog} onClose={handleCancelAdd}>
                <DialogTitle>Wort bereits vorhanden</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Das Wort "{foreignWord}" existiert bereits in der Datenbank:
                        {existingWord && (
                            <>
                                <br />
                                Deutsch: {existingWord.german}
                                <br />
                                {language.language}: {existingWord.foreign}
                                {existingWord.additionalInfo && (
                                    <>
                                        <br />
                                        Zusatzinfo: {existingWord.additionalInfo}
                                    </>
                                )}
                            </>
                        )}
                        <br /><br />
                        Möchten Sie es trotzdem hinzufügen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelAdd} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleConfirmAdd} color="primary" autoFocus>
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openImportDialog} onClose={handleImportCancel} maxWidth="md" fullWidth>
                <DialogTitle>Importierte Vokabeln überprüfen</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Button onClick={handleImportConfirm} color="primary">
                                    OK
                                </Button>
                                <Button onClick={handleImportCancel} color="secondary">
                                    Abbrechen
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}><Typography variant="h6">Deutsch</Typography></Grid>
                                <Grid item xs={4}><Typography variant="h6">{language.language}</Typography></Grid>
                                <Grid item xs={4}><Typography variant="h6">Zusatzinfo</Typography></Grid>
                            </Grid>
                            {importedVocabs.map((vocab, index) => (
                                <Grid container spacing={2} key={index} alignItems="center">
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            value={vocab.germanWord}
                                            onChange={(e) => handleVocabChange(index, 'germanWord', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            value={vocab.foreignWord}
                                            onChange={(e) => handleVocabChange(index, 'foreignWord', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            value={vocab.additionalInfo}
                                            onChange={(e) => handleVocabChange(index, 'additionalInfo', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button onClick={() => handleVocabDelete(index)} color="secondary">
                                            X
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}