import { useState, useCallback } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

export const useUpdateScore = (userId) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

  const updateScore = useCallback(async (nextKnownCount) => {
    setIsUpdating(true);
    setError(null);

    try {
      const userStatsRef = doc(db, 'userStats', userId);
      const docSnap = await getDoc(userStatsRef);

      if (docSnap.exists()) {
        const { dayScore, dayDate, totalScore } = docSnap.data();

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const storedDate = dayDate ? dayDate.toDate() : new Date(0);
        storedDate.setHours(0, 0, 0, 0);

        let newDayScore = dayScore || 0;
        let newTotalScore = totalScore || 0;

        if (storedDate < today) {
          // It's a new day, reset dayScore
          newDayScore = nextKnownCount;
          await updateDoc(userStatsRef, {
            dayDate: today,
            dayScore: newDayScore,
            totalScore: newTotalScore + nextKnownCount
          });
        } else {
          // Same day, update both scores
          newDayScore += nextKnownCount;
          newTotalScore += nextKnownCount;
          await updateDoc(userStatsRef, {
            dayScore: newDayScore,
            totalScore: newTotalScore
          });
        }

        return { dayScore: newDayScore, totalScore: newTotalScore };
      } else {
        // Document doesn't exist, create it
        const newStats = {
          dayDate: new Date(),
          dayScore: nextKnownCount,
          totalScore: nextKnownCount
        };
        await updateDoc(userStatsRef, newStats);
        return { dayScore: nextKnownCount, totalScore: nextKnownCount };
      }
    } catch (err) {
      setError(err);
      console.error('Error updating score:', err);
    } finally {
      setIsUpdating(false);
    }
  }, [userId]);

  return { updateScore, isUpdating, error };
};