import { useState } from 'react';
import { auth } from '../../firebase/config';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';

const debug = false;

export function useFetchVocab() {
  const [vocabs, setVocabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchVocabs = async (filters = {}, selectedFields = null) => {
    setLoading(true);
    setError(null);

    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        throw new Error('User not authenticated');
      }

      const vocabCollection = collection(db, 'vocabList');

      let constraints = [where('userId', '==', userId)];

      Object.entries(filters).forEach(([field, value]) => {
        if (Array.isArray(value)) {
          if (value[0] === '<=') {
            constraints.push(where(field, '<=', value[1]));
          } else if (value[0] === '>=') {
            constraints.push(where(field, '>=', value[1]));
          } else if (value[0] === '==') {
            constraints.push(where(field, '==', value[1]));
          }
        } else {
          constraints.push(where(field, '==', value));
        }
      });

      const q = query(vocabCollection, ...constraints);

      if (debug) console.log('Firestore query:', q);

      const querySnapshot = await getDocs(q);

      if (debug) console.log('Query snapshot:', querySnapshot);

      let fetchedVocabs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      if (debug) console.log('Fetched vocabs:', fetchedVocabs);

      if (selectedFields) {
        fetchedVocabs = fetchedVocabs.map(vocab => {
          const filteredVocab = { id: vocab.id };
          selectedFields.forEach(field => {
            if (field in vocab) {
              filteredVocab[field] = vocab[field];
            }
          });
          return filteredVocab;
        });
      }

      setVocabs(fetchedVocabs);
    } catch (err) {
      if (debug) console.error('Error in fetchVocabs:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    vocabs,
    loading,
    error,
    fetchVocabs
  };
}
