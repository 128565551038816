import { useState, useCallback, useRef, useEffect } from 'react';

export const useChat = () => {
  const [messages, setMessages] = useState([]);
  const messagesContainerRef = useRef(null);

  const addMessage = useCallback((message) => {
    setMessages(prevMessages => [message, ...prevMessages]);
  }, []);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = 0;
    }
  }, [messages]);

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return {
    messages,
    messagesContainerRef,
    addMessage,
    clearMessages
  };
};