// createContext.js
import React, { useState } from 'react';

export const TrainingContext = React.createContext();

export const TrainingProvider = ({ children }) => {
  const [trainingsVocab, setTrainingsVocab] = useState([]);
  const [wrongVocab, setWrongVocab] = useState([]);
  const [isCheckingWrongVocab, setIsCheckingWrongVocab] = useState(false);

  return (
    <TrainingContext.Provider value={{ trainingsVocab, setTrainingsVocab, wrongVocab, setWrongVocab, isCheckingWrongVocab, setIsCheckingWrongVocab }}>
      {children}
    </TrainingContext.Provider>
  );
};