import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from '../firebase/config';
import { setCurrentUser } from '../redux/userSlice';

export function UserAuthListener({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // Extrahiere nur die benötigten, serialisierbaren Eigenschaften
        const serializableUser = {
          uid: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          displayName: user.displayName,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL
        };
        dispatch(setCurrentUser(serializableUser));
      } else {
        dispatch(setCurrentUser(null));
      }
    });

    return unsubscribe;
  }, [dispatch]);

  return children;
}