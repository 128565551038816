import { useState, useEffect } from "react";
import { db } from "../firebase/config";
import { collection, query, where, onSnapshot } from "firebase/firestore";   

export const useUserLanguages = (c, userId) => {  
    const [documents, setDocuments] = useState(null);
    const [error, setError] = useState(null);

    //console.log('Running useUserLanguages with userId:', userId);

    useEffect(() => {
        //console.log('useEffect called');
        if (userId) {
            //console.log('userId is defined');
            let ref = collection(db, c);
            let q = query(ref, where("userId", "==", userId));

            const unsub = onSnapshot(q, (snapshot) => {
                //console.log('onSnapshot called');
                try {
                    let results = []
                    snapshot.docs.forEach(doc => {
                        results.push({...doc.data(), id: doc.id })
                    })
                    //console.log('Fetched documents:', results);
                    setDocuments(results)
                } catch (err) {
                    //console.log('Error in onSnapshot:', err);
                    setError(err);
                }
            })

            return () => unsub()
        } else {
            console.log('userId is not defined');
        }
    }, [c, userId])
    return { documents, error }
}