import React, { useState } from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import { Send } from 'lucide-react';

const VocabInput = ({ onSubmit }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = () => {
    if (inputValue.trim()) {
      onSubmit(inputValue);
      setInputValue('');
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        position: 'relative',
      }}
    >
      <TextField
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
        placeholder="Gib deine Antwort ein"
        variant="outlined"
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.5)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              color="primary"
              onClick={handleSubmit}
              disabled={!inputValue.trim()}
              edge="end"
            >
              <Send size={20} />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default VocabInput;