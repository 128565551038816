// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyyfx15HSIMWAahkZaU7gC5jNaYFwdUm8",
  authDomain: "vokabeltrainer-58986.firebaseapp.com",
  projectId: "vokabeltrainer-58986",
  storageBucket: "vokabeltrainer-58986.appspot.com",
  messagingSenderId: "50701315916",
  appId: "1:50701315916:web:cd8ccfc14374e4dc3869e9",
  measurementId: "G-JKNZKBNZM4"
};

// Initialize Firebase
initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore();

// Initialize Authentication
const auth = getAuth();

export { db, auth };