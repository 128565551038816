import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

const useHasError = (vocabId, isReversedOrder) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const checkForError = async () => {
      if (!vocabId) {
        setHasError(false);
        return;
      }

      const vocabRef = doc(db, 'vocabList', vocabId);
      const vocabDoc = await getDoc(vocabRef);

      if (vocabDoc.exists()) {
        const vocabData = vocabDoc.data();
        if (vocabData.errors) {
          const errorField = isReversedOrder ? 'foreign_to_de' : 'de_to_foreign';
          const languageErrors = vocabData.errors[errorField];
          
          // Check if there are any language entries in the error object
          const hasLanguageErrors = languageErrors && Object.keys(languageErrors).length > 0;
          
          setHasError(hasLanguageErrors);
        } else {
          setHasError(false);
        }
      } else {
        setHasError(false);
      }
    };

    checkForError();
  }, [vocabId, isReversedOrder]);

  return hasError;
};

export default useHasError;