import React, { createContext, useState, useContext } from 'react';

const RecordingContext = createContext();

export function useRecording() {
  return useContext(RecordingContext);
}

export function RecordingProvider({ children }) {
  const [isRecording, setIsRecording] = useState(false);

  const value = {
    isRecording,
    setIsRecording
  };

  return (
    <RecordingContext.Provider value={value}>
      {children}
    </RecordingContext.Provider>
  );
}