import { useState } from 'react';
import OpenAI from 'openai';

const useAiTranslation = () => {
    const [response, setResponse] = useState('');

    const openai = new OpenAI({ 
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true 
    });
    const getResponse = async (prompt) => {
    try {
        const completion = await openai.chat.completions.create({
        messages: [
            { role: "system", content: "Du bist ein Sprachenexperte, der bei der punktgenauen übersetzung von Vokabeln und Sätzen helfen soll. Du antwortest genau mit dem gesuchten Wort, Zum Beispiel Das Wort 'Baum' wird in ENglisch angefragt, so lautet deine Antwort: 'tree'. Das war es. Nicht 'Tree', nicht 'Das Wort lautet: tree.'." },
            { role: "user", content: prompt }
        ],
        model: "gpt-4o",
        });
        const responseContent = completion.choices[0].message.content;
        setResponse(responseContent);
        return responseContent;
    } catch (error) {
        console.error('Error fetching response from OpenAI:', error);
    }
    };

    return { response, getResponse };
};

export default useAiTranslation;        
