import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      if (action.payload) {
        // Ensure we're only storing serializable data
        state.currentUser = {
          uid: action.payload.uid,
          email: action.payload.email,
          emailVerified: action.payload.emailVerified,
          displayName: action.payload.displayName,
          phoneNumber: action.payload.phoneNumber,
          photoURL: action.payload.photoURL
        };
      } else {
        state.currentUser = null;
      }
    }
  }
});

export const { setCurrentUser } = userSlice.actions;
export default userSlice.reducer;