import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Chip } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TodayIcon from '@mui/icons-material/Today';
import { useScoreUpdate } from '../hooks/useScoreUpdate';

const ScoreBox = () => {
  const currentUser = useSelector(state => state.user.currentUser);
  const { dayScore, totalScore, checkAndUpdateScore } = useScoreUpdate(currentUser?.uid);

  useEffect(() => {
    if (currentUser?.uid) {
      checkAndUpdateScore();
    }
  }, [currentUser, checkAndUpdateScore]);

  if (!currentUser || !currentUser.emailVerified) return null;

  return (
    <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
      <Chip
        icon={<TodayIcon />}
        label={`Tag: ${dayScore}`}
        color="secondary"
        size="small"
        sx={{ mr: 1, fontWeight: 'bold' }}
      />
      <Chip
        icon={<EmojiEventsIcon />}
        label={`Gesamt: ${totalScore}`}
        color="secondary"
        size="small"
        sx={{ fontWeight: 'bold' }}
      />
    </Box>
  );
};

export default ScoreBox;