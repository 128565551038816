import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const ChatUI = ({ 
  messages, 
  messagesContainerRef, 
  userName 
}) => {
  return (
    <Box 
      width="100%" 
      maxHeight="400px" 
      overflow="auto" 
      ref={messagesContainerRef}
    >
      {messages.map((message, index) => (
        <Box key={index} mb={2} display="flex" justifyContent={message.type === 'user' ? 'flex-end' : 'flex-start'}>
          <Card style={{ maxWidth: '70%', backgroundColor: message.type === 'user' ? '#e3f2fd' : '#f3e5f5' }}>
            <CardContent>
              <Typography variant="body1" component="div">
                <strong>{message.type === 'user' ? userName : 'KI Trainer'}:</strong>
                {' '}
                <ReactMarkdown>{message.text}</ReactMarkdown>
              </Typography>
            </CardContent>
          </Card>
        </Box>
      ))}
    </Box>
  );
};

export default ChatUI;