import React, { useState } from 'react';
import { useLogin, getErrorMessage } from '../hooks/useLogin';
import { TextField, Button, Card, CardContent, Typography, Container } from '@mui/material';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, updateProfile } from "firebase/auth";

export default function Login({ navigateTo }) {
  const { email, setEmail, password, setPassword } = useLogin();
  const [resetError, setResetError] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (!user.emailVerified) {
        setLoginError(getErrorMessage('auth/email-not-verified'));
        return;
      }
      if (!user.displayName) {
        const name = prompt('Hey, wie heißt du?');
        await updateProfile(user, { displayName: name });
      }
      navigateTo('/');
    } catch (error) {
      setLoginError(getErrorMessage(error.code));
      console.error(error);
    }
  }

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Check deine Mails! Wir haben dir Anweisungen zum Zurücksetzen deines Passworts geschickt.');
    } catch (error) {
      setResetError(getErrorMessage(error.code));
    }
  }

  const handleResendVerificationEmail = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      alert('Alles klar! Wir haben dir die Bestätigungs-Mail nochmal geschickt.');
    } catch (error) {
      setLoginError(getErrorMessage(error.code));
      console.error(error);
    }
  }

  return (
    <Container maxWidth="xs">
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>Los geht's!</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Deine E-Mail"
              type="email"
              required
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
            />
            <TextField
              label="Dein Passwort"
              type="password"
              required
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '20px' }}
            >
              Einloggen
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ marginTop: '20px' }}
              onClick={handleResetPassword}
            >
              Passwort vergessen?
            </Button>
            {loginError && <Typography color="error" style={{ marginTop: '20px' }}>{loginError}</Typography>}
            {loginError && <Button variant="contained" color="secondary" fullWidth style={{ marginTop: '20px' }} onClick={handleResendVerificationEmail}>Bestätigungs-Mail nochmal senden</Button>}
            {resetError && <Typography color="error" style={{ marginTop: '20px' }}>{resetError}</Typography>}
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}